import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import {
  AppBar,
  Card,
  Drawer,
  Modal,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../components/Logo/Logo';
import ApplicationsNavItem from './ApplicationsMenu/ApplicationsNavItem';
import { ApplicationsMenuData } from './ApplicationsMenu/ApplicationsMenuData';
import { UserContext } from '../providers/UserProvider';
import SidebarData from './Menu/SideBarData';
import DashboardSidebar from '../components/dashboard/DashboardSidebar';
import IndexedDbWrapper from '../utils/indexedDbWrapper';
import { config } from '../firebase';
import OnlineStatus from './OnlineStatus';
import CloudSync from './CloudSync';

import { _HeaderTitle } from '../_Recoil/atoms';
// import { functions } from '../firebase';

const Header = (props) => {
  const user = useContext(UserContext);
  const { appTitle } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [drawer, setDrawer] = useState(false);
  const [applicationDrawer, setApplicationDrawer] = useState(false);
  const HeaderTitle = useRecoilValue(_HeaderTitle);

  const application2 = () => {
    if (user && (user.admin || user.user)) {
      // const userMenu = functions.httpsCallable('getMenu');
      // userMenu({ email: user.email }).then(r => {
      // });
      return ApplicationsMenuData.map((item) => (
        <ApplicationsNavItem
          key={item.title}
          href={item.href}
          title={item.title}
          icon={item.logo}
        />
      ));
    }
    return null;
  };
  return (
    <>
      <AppBar className='header' position='relative'>
        <Toolbar className='navbar' style={{ paddingLeft: '0px', paddingRight: '4px' }}>
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
            {user && !matches && (user && !user.payroll) ?
              <FontAwesomeIcon icon={faBars} style={{ margin: '12px', cursor: 'pointer' }} onClick={() => { setDrawer(true); }} /> : null}
            {(user && !user.payroll) ? <RouterLink to='/' style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', paddingLeft: matches ? '12px' : '0px' }}>
              <Logo />
            </RouterLink> : <Logo />}
            <span style={{ paddingLeft: '5px', color: 'white', fontSize: '1.25rem' }}>{HeaderTitle || appTitle}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {user && !matches ? (<>
              {SidebarData.map(d => (
                d.dock ? <div style={{ padding: '5px' }} key={d.title}><RouterLink to={d.href} style={{ color: 'white', textTransform: 'none' }}>{d.title}</RouterLink></div> : null
              ))}
            </>) : null}
            {/* <FontAwesomeIcon style={{ color: 'white', margin: '8px', cursor: 'pointer' }} onClick={() => { setApplicationDrawer(!applicationDrawer) }} icon={faTh} /> */}
            <CloudSync />
            <div style={{ margin: '0px 6px 0px 1px' }}><OnlineStatus /></div>
            <IndexedDbWrapper projectId={config.projectId} />
          </div>
        </Toolbar>
        <div className='brandContainer'>
          <div className='brandBar' />
          <a href='https://www.aldridgegroup.com/'>aldridgegroup.com</a>
        </div>
      </AppBar>
      <Drawer
        anchor='left'
        onClose={() => setDrawer(false)}
        open={drawer}
        variant='temporary'
        PaperProps={{
          sx: {
            width: 256
          }
        }}
      >
        <DashboardSidebar setApplicationDrawer={() => setDrawer(false)} />
      </Drawer>
      {applicationDrawer ?
        <Modal
          open={applicationDrawer}
          onClose={() => setApplicationDrawer(false)}
          BackdropProps={{ style: { backgroundColor: 'transparent' } }}>
          <Card style={{ position: 'fixed', top: '50px', right: '10px', zIndex: '2500', width: '290px', overflow: 'auto', backgroundColor: 'white' }}>
            <div style={{ width: '100%', height: '100%', overflow: 'hidden', padding: '10px 0px 30px 0px' }}>
              {application2()}
            </div>
          </Card>
        </Modal> : null
      }
    </>
  );
};
Header.propTypes = {
  appTitle: PropTypes.string,
};
Header.defaultProps = {
  appTitle: '',
};

export default Header;