import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { faTrashAlt, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { InputText, InputSelect } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { deleteDoc, onSnapshot, setDoc, doc, collection, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { _CurrentForeman } from '../../_Recoil/atoms';
import { icons, basicStyles } from '../../theme';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1)
  }
}));

const GroupsList = (props) => {
  const [ForemanGroup, setForemanGroup] = useState([]);
  const { history } = props;
  const classes = basicStyles();
  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const mounted = useRef(null);
  const handleSelect = (c) => {
    if (CurrentForeman.value[0] === c.data.Foreman) {
      history.push(`/groups/${c.id}`);
    } else {
      toast.info('This is not your group member to modify.');
    }
  };
  const addNew = () => {
    if (CurrentForeman.value.length > 0)
      history.push(`/groups/new`);
    else
      toast.error('You must select a Manager before adding a group Member...');
  };

  const getRecords = () => {
    const colRefForemansGroup = (() => {
      let col = collection(firestore, 'Group-Manager');
      if (CurrentForeman.value.length > 0) col = query(col, where('data.Foreman', '==', CurrentForeman.value[0]));
      else col = undefined;
      return col;
    })();
    if (typeof colRefForemansGroup !== 'undefined') {
      onSnapshot(colRefForemansGroup, (snap) => {
        const rec = [];
        snap.forEach(d => rec.push(d.data()));
        if (mounted.current)
          setForemanGroup(rec);
      });
    } else if (mounted.current) {
      setForemanGroup([]);
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current)
      getRecords();
    return () => (mounted.current = false);
  }, [CurrentForeman]);

  const removeRecord = async (r) => {
    await deleteDoc(doc(firestore, 'Group-Manager', r.id));
    toast.success(`Removed ${r.data.EmployeeDisplay} from the group.`);
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '16px'
        }}
      >
        {
          matches ?
            <div className={`${iconClasses.container} ${iconClasses.addContainer}`} style={{ margin: '8px 0px -10px 0px' }}>
              <Button onClick={addNew} disableRipple className={iconClasses.buttonWrapper}>
                <FontAwesomeIcon icon={faPlus} className={iconClasses.icon} title="Add New Group Member" />
              </Button>
            </div>
            : null
        }
      </div>
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className={classes.tableHeader}>Name</TableCell>
                <TableCell className={classes.tableHeader}>Status</TableCell>
                {matches ?
                  <TableCell className={classes.tableHeader} colSpan={3}>Foreman</TableCell> : (
                    <TableCell className={classes.tableHeader} style={{ paddingRight: '0px' }} colSpan={3}>
                      <Grid container style={{ alignItems: 'center' }}>
                        <Grid item xs={10} align='right'>
                          <div className={`${iconClasses.container} ${iconClasses.addContainer}`} style={{ margin: '4px 1px 4px 0px' }}>
                            <Button onClick={addNew} disableRipple className={iconClasses.buttonWrapper}>
                              <FontAwesomeIcon icon={faPlus} className={iconClasses.icon} title="Add New Group Member" />
                            </Button>
                          </div>
                        </Grid></Grid>
                    </TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {ForemanGroup.map(group => (
                <TableRow className={classes.row1} key={group.id}>
                  <TableCell className={classes.tableCell} onClick={() => handleSelect(group)}>
                    {group.data.GroupName}
                  </TableCell>
                  <TableCell className={classes.tableCell} onClick={() => handleSelect(group)}>
                    {group.data.Status}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='right'>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{ cursor: 'pointer', fontSize: '20px' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeRecord(group);
                      }} />

                  </TableCell>
                </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};
GroupsList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
};
GroupsList.defaultProps = {};

const GroupListSelection = (props) => {
  // const classes = basicStyles();
  const customClasses = useStyles();
  const { history, match } = props;
  const user = useContext(UserContext);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const mounted = useRef(null);
  const [record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      JobNumber: '',
      Foreman: CurrentForeman.value[0] || '',
      ForemanDisplay: CurrentForeman.shortDisplay || '',
      GroupName: '',
      Status: 'Active'
    }
  });

  useEffect(() => {
    if (match.params.id === 'new') return;
    mounted.current = true;
    onSnapshot(doc(firestore, 'Group-Manager', match.params.id), (snap) => {
      if (snap.exists) {
        const r = snap.data();
        if (mounted.current)
          setRecord({ ...r });
      }
    });
    // eslint-disable-next-line consistent-return
    return () => (mounted.current = false);
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...record };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addGroupMember = () => {
    const savedRecord = JSON.parse(JSON.stringify(record));
    if (record.data.GroupName === '') {
      toast.error('You need to enter a group name.');
      return;
    }
    if (match.params.id === 'new') {
      const docRef = doc(collection(firestore, 'Group-Manager'));
      savedRecord.id = docRef.id;
      setDoc(docRef, savedRecord);
      // toast.success('group Member Added.');
    } else {
      setDoc(doc(firestore, 'Group-Manager', savedRecord.id), savedRecord, { merge: true });
    }
    toast.success(`Successfully added ${record.data.EmployeeDisplay}`);
    history.push('/groups');
  };
  const backToGroup = () => {
    history.push('/groups');
  };

  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div style={{ paddingTop: '16px' }}>
      <div style={{
        position: 'sticky', top: '-1px', left: 0, right: 0, width: '100%', padding: '8px', paddingRight: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100
      }}
      >
        <div className={`${iconClasses.container} ${iconClasses.greenContainer}`} style={{ margin: matches ? '' : '0px 12px' }} title="Save Record">
          <Button onClick={addGroupMember} disableRipple className={iconClasses.buttonWrapper}>
            <FontAwesomeIcon icon={faSave} className={iconClasses.icon} />
          </Button>
        </div>
        <div className={`${iconClasses.container} ${iconClasses.redContainer}`} style={{ margin: '0px 0px 0px 12px' }} title="Close Record">
          <Button onClick={backToGroup} disableRipple className={iconClasses.buttonWrapper}>
            <FontAwesomeIcon icon={faTimes} className={iconClasses.icon} />
          </Button>
        </div>
      </div>
      <Paper className={customClasses.paper}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: '8px' }}>
            <InputText
              label="Group Name"
              name="GroupName"
              onChange={onChange}
              value={record.data.GroupName || ''}
            />
          </Grid>
          <InputSelect
            label="Status"
            name="Status"
            onChange={onChange}
            value={record.data.Status || ''}
            options={[{ value: 'Active', label: 'Active' },
            { value: 'Inactive', label: 'Inactive' }]}
          />
        </Grid>
      </Paper>

    </div>
  );
};

GroupListSelection.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
GroupListSelection.defaultProps = {};

export { GroupListSelection, GroupsList };
