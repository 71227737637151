import React, { useEffect } from 'react';
import { Grid, Card } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import DailyTimeDashboard from '../components/dashboard/timeDashboard';
import CrewDashboard from '../components/dashboard/CrewDashboard';
import GroupDashboard from '../components/dashboard/GroupDashboard';
import { _HeaderTitle, _CurrentDate, _CurrentForeman } from '../_Recoil/atoms';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import ErrorBoundary from '../utils/ErrorBoundary';

const Dashboard = () => {
  document.title = 'Dashboard';
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const showCards =
    CurrentForeman.value.length !== 0 && CurrentDate.value !== '';

  useEffect(() => {
    setHeaderTitle('Daily Planning Overview');
  }, []);
  return (
    <ErrorBoundary componentName='Dashboard'>
      <div style={{ padding: '5px' }}>
        <DailyToolbar />
      </div>
      <Grid container justifyContent='center'>
        {showCards ? (
          <Grid item md={8} xs={12} style={{ padding: '5px' }}>
            <Card style={{ marginTop: '8.1px' }}>
              <DailyTimeDashboard />
            </Card>
          </Grid>
        ) : (
          <div style={{ padding: '10px 5px 5px 5px', width: '100%' }}>
            <Card style={{ padding: '10px 5px 10px 5px' }}>
              <i>
                Please complete the Job Details by clicking the pen to see a
                daily overview.
              </i>
            </Card>
          </div>
        )}
        <Grid
          item
          md={showCards ? 4 : 12}
          xs={12}
          style={{ padding: '5px', marginTop: showCards ? '0px' : '10px' }}
        >
          <GroupDashboard />
          <CrewDashboard />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};
export default Dashboard;
