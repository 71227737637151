import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faHome,
  // faList,
  // faWatch,
  faUserFriends,
  faUsers,
  // faHardHat,
  // faTruckContainer,
  // faDollarSign,
  // faClipboard,
  // faFilePdf,
  // faCalculatorAlt
} from '@fortawesome/pro-regular-svg-icons';

const SidebarData = [
  {
    href: '/',
    icon: faHome,
    title: 'Home',
    dock: false
  },
  // {
  //   href: '/pod',
  //   icon: faList,
  //   title: 'P.O.D.',
  //   dock: true,
  // },
  // {
  //   href: '/time-entry',
  //   icon: faWatch,
  //   title: 'Time',
  //   dock: true
  // },
  {
    href: '/time-summary',
    icon: faCalendarAlt,
    title: 'Weekly Time Summary',
    dock: true
  },
  // {
  //   href: '/accounting',
  //   icon: faCalculatorAlt,
  //   title: 'Accounting',
  //   dock: false,
  // },
  // {
  //   href: '/Daily-Report',
  //   icon: faClipboard,
  //   title: 'DJR',
  //   dock: true
  // },
  // {
  //   href: '/ReportingTool',
  //   icon: faFilePdf,
  //   title: 'PDF',
  //   dock: false
  // },
  {
    href: '/crew',
    icon: faUserFriends,
    title: 'Crew',
    dock: true
  },
  {
    href: '/groups',
    icon: faUsers,
    title: 'Groups',
    dock: true
  }
  // {
  //   href: '/Distribution',
  //   icon: faAddressBook,
  //   title: 'Distribution',
  //   dock: false,
  // },
  // {
  //   href: '/Equipment',
  //   icon: faTruckContainer,
  //   title: 'Equipment',
  //   dock: false,
  // },
  // {
  //   href: '/Subcontractor',
  //   icon: faHardHat,
  //   title: 'Subcontractor',
  //   dock: false,
  // },
  // {
  //   href: '/wbs',
  //   icon: faDollarSign,
  //   title: 'WBS/PICC',
  //   dock: false
  // },
];
export default SidebarData;
