import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../firebase';

export const UserContext = createContext({ user: null });
const UserProvider = (props) => {
  const [user, setUser] = useState({});
  const { children } = props;
  const [signingIn, setSigningIn] = useState(false);
  useEffect(() => {
    let mounted = true;
    auth.onAuthStateChanged(async () => {
      if (mounted)
        setSigningIn(true);
      if (auth.currentUser) {
        if (localStorage.getItem('token') !== null && mounted) {
          setUser(JSON.parse(localStorage.getItem('token')));
          setSigningIn(false);
        }
        auth.currentUser.getIdToken(true).then((idToken) => {
          const checkIfUserExists = httpsCallable(functions, 'userExists');
          checkIfUserExists({ idToken, host: window.location.host })
            .then(async () => {
              const token = await auth.currentUser.getIdTokenResult(true);
              if (mounted) {
                setSigningIn(false);
                setUser(token.claims);
              }
              localStorage.setItem('token', JSON.stringify(token.claims));
            })
            .catch((err) => {
              toast.error(err.message, { autoClose: 8000 });
            });
        });
      } else if (mounted) {
        localStorage.removeItem('token');
        setSigningIn(false);
        setUser(null);
      }
    });
    return () => (mounted = false);
  }, []);

  return <UserContext.Provider value={user}>
    {signingIn ? <div style={{
      display: 'flex',
      position: 'fixed',
      zIndex: 2600,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      opacity: '.70'
    }}>
      <Loader
        type="TailSpin"
        color="#3b78e7"
        height={150}
        width={150}
      />
      Signing In...

    </div> : null
    }
    {children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any)
};
UserProvider.defaultProps = {
  children: [],
};
export default UserProvider;
