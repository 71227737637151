import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import {
  InputAutocomplete,
  InputSelect,
  InputNumber,
  InputDate,
  InputCheckbox,
  InputCheckboxGroup,
  // InputWeekdays
} from '@aldridge/aldg-data-components';
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
  setDoc
} from 'firebase/firestore';

import { isUndefined } from '@aldridge/aldg-helpers';
import { _CurrentDate, _CurrentForeman } from '../../_Recoil/atoms';
import { firestore, firebaseConfig } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';
import { weekEndDate } from '../../utils/dateFunctions';
import InputWeekdays from './InputWeekdays';

const TimeSummaryModalRecord = (props) => {
  const { closePopup, record, employee, getRecords } = props;
  const mounted = useRef(null);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const [PerDiems, setPerDiems] = useState([]);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const user = useContext(UserContext);
  const [loadedEmp, setLoadedEmp] = useState({ flag: false, value: undefined });
  const [entry, setEntry] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      Crew: '',
      TimeEntryPoint: 'ManagerTimeEntryRecord',
      Dates: [CurrentDate.value],
      Date: CurrentDate.value,
      WeekEndDate: weekEndDate(CurrentDate.value),
      Doubletime: '',
      ExemptFromCertifiedPayroll: '',
      Holiday: '',
      JCTDSCID: '',
      JobNumber: '',
      JobDisplay: '',
      LessThan8HoursVerified: '',
      NoWorkRecorded: '',
      Overtime: '',
      PRTUNMID: '',
      PerDiem: '',
      Employee: employee,
      EmployeeDisplay: '',
      Person: '',
      PersonDisplay: '',
      PersonJobPiccDisplay: '',
      Picc: '',
      PiccDisplay: '',
      PayItem: '',
      PayItemDisplay: '',
      Regular: '',
      Shift: '1',
      SickLeave: '',
      SickLeaveType: '',
      Union: '',
      UnionDisplay: '',
      Vacation: ''
    }
  });

  useEffect(() => {
    mounted.current = true;
    if (typeof record !== 'undefined') {
      if (mounted.current) {
        setEntry(record);
      }
    }
    const pds = [];
    const fRef = query(collection(firestore, 'PerDiems'), where('status', '==', 'A'), orderBy('order'));
    onSnapshot(fRef, (res) => {
      res.forEach((d) => {
        pds.push(d.data());
      });
      if (mounted.current) setPerDiems(pds);
    });
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (loadedEmp.flag && typeof loadedEmp.value !== 'undefined') {
      const uEntry = { ...entry };
      uEntry.data.Crew = loadedEmp.value;
      if (mounted.current)
        setEntry(uEntry);
    }
    return () => (mounted.current = false);
  }, [loadedEmp]);

  const RoundTo25 = (value) => {
    const cleanedValue = (isUndefined(value) ? '' : value.toString()).replace(/[^0-9.]/g, '');
    const numberValue = (Number.isNaN(cleanedValue) || cleanedValue.toString().trim() === "") ? 0 : `0${cleanedValue}`;
    const vMod = parseFloat(numberValue) % 1;
    let finalValue = numberValue;
    try {
      switch (true) {
        case vMod <= .125: finalValue = parseInt(finalValue, 10) + 0; break;
        case vMod <= .375: finalValue = parseInt(finalValue, 10) + .25; break;
        case vMod <= .625: finalValue = parseInt(finalValue, 10) + .50; break;
        case vMod <= .875: finalValue = parseInt(finalValue, 10) + .75; break;
        default: finalValue = parseInt(finalValue, 10) + 1;
      }
    } catch (ex) { finalValue = 0; }
    if (finalValue > 24) finalValue = 24;
    return finalValue;
  };
  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...entry };
      changedRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      const TimeTypeFields = [
        "Doubletime", "Holiday", "Overtime", "Regular", "SickLeave", "Vacation"
      ];
      if (TimeTypeFields.indexOf(id) > -1) {
        changedRecord.data[id] = RoundTo25(value);
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setEntry(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const close = () => {
    if (mounted.current)
      setEntry({
        id: '',
        CreatedBy: '',
        CreatedDate: '',
        ModifiedBy: '',
        ModifiedDate: '',
        data: {
          Crew: '',
          TimeEntryPoint: 'ManavgerTimeEntryRecord',
          Date: CurrentDate.value,
          WeekEndDate: weekEndDate(CurrentDate.value),
          Doubletime: '',
          ExemptFromCertifiedPayroll: '',
          Holiday: '',
          JCTDSCID: '',
          JobDisplay: '',
          LessThan8HoursVerified: '',
          NoWorkRecorded: '',
          Overtime: '',
          PRTUNMID: '',
          PerDiem: '',
          Employee: '',
          EmployeeDisplay: '',
          Person: '',
          PersonDisplay: '',
          PersonJobPiccDisplay: '',
          Picc: '',
          PiccDisplay: '',
          PayItem: '',
          PayItemDisplay: '',
          Regular: '',
          Shift: '1',
          SickLeave: '',
          SickLeaveType: '',
          Union: '',
          UnionDisplay: '',
          Vacation: ''
        }
      });
    closePopup();
  };

  const isValidRecord = () => {
    let valid = true;
    const err = [];
    let perDiemRecord = false;
    let noWorkRecord = false;
    if (entry.data.PerDiem !== '') {
      perDiemRecord = true;
    }
    if (entry.data.NoWorkRecorded === 'true') {
      noWorkRecord = true;
    }
    if (entry.data.Crew.length === 0) {
      valid = false;
      err.push('You must select an Employee to Enter Time for.');
    }
    if (entry.data.JCTDSCID.length === 0) {
      valid = false;
      err.push('You must select a Job Number to Enter Time for.');
    }
    if (entry.data.Union.length === 0) {
      valid = false;
      err.push('You must select a Union to Enter Time for.');
    }
    if (entry.data.Picc.length > 0 && noWorkRecord) {
      valid = false;
      err.push('You cannot select a PICC for No Work Recorded.');
    }
    if (entry.data.Picc.length === 0 && !perDiemRecord && !noWorkRecord) {
      valid = false;
      err.push('You must select a PICC to Enter Time for.');
    }
    if (entry.data.Shift === '' && !perDiemRecord && !noWorkRecord) {
      valid = false;
      err.push('You must select a Shift to Enter Time for.');
    }
    if (entry.data.Shift !== '' && noWorkRecord) {
      valid = false;
      err.push('You cannot select a Shift for No Work Recorded.');
    }
    if (
      entry.data.Regular === '' &&
      entry.data.Overtime === '' &&
      entry.data.Doubletime === '' &&
      entry.data.Holiday === '' &&
      entry.data.Vacation === '' &&
      entry.data.SickLeave === '' &&
      !perDiemRecord &&
      !noWorkRecord
    ) {
      valid = false;
      err.push('You must enter Hours to Enter Time for.');
    }
    if (
      (entry.data.Regular !== '' ||
        entry.data.Overtime !== '' ||
        entry.data.Doubletime !== '' ||
        entry.data.Holiday !== '' ||
        entry.data.Vacation !== '' ||
        entry.data.SickLeave !== '') &&
      noWorkRecord
    ) {
      valid = false;
      err.push('You cannot enter Hours for No Work Recorded.');
    }
    return { valid, err: err.join(' ') };
  };

  const createSingleTimeRecord = async (singleEntry) => {
    const cEntry = JSON.parse(JSON.stringify(singleEntry));
    const validRecord = isValidRecord();
    const docRef = doc(collection(firestore, 'TimeEntries'));
    if (validRecord.valid) {
      if (cEntry.id === '') {
        const EmployeeRecord = (
          await getDoc(doc(firestore, 'Crew-Manager', cEntry.data.Crew[0]))
        ).data();

        toast.success('Time Entered Succesfully.');

        [cEntry.data.Employee] = EmployeeRecord.data.Employee;
        cEntry.data.EmployeeDisplay = EmployeeRecord.data.EmployeeDisplay;

        cEntry.data.PersonJobPiccDisplay = `${EmployeeRecord.data.EmployeeDisplay}\n${cEntry.data.JobNumberDisplay}\n${cEntry.data.PiccDisplay}`;

        cEntry.id = docRef.id;
        setDoc(docRef, cEntry, { merge: true });
        getRecords(true);
      } else {
        const newCEntry = { ...cEntry };
        setDoc(doc(collection(firestore, 'TimeEntries'), newCEntry.id), newCEntry, { merge: true });
        getRecords(true);
      }
    } else {
      toast.error(validRecord.err);
    }
  };

  const createMultipleTimeRecords = () => {
    const multiEntry = JSON.parse(JSON.stringify(entry));
    const { Dates } = multiEntry.data;
    for (let i = 0; i < Dates.length; i++) {
      multiEntry.id = '';
      delete multiEntry.data.Dates;
      multiEntry.data.Date = Dates[i];
      createSingleTimeRecord(multiEntry);
    }
    close();
  };

  const createTimeRecord = async (timeEntry = JSON.parse(JSON.stringify(entry))) => {
    const cEntry = JSON.parse(JSON.stringify(timeEntry));
    if (typeof cEntry.data.Dates !== 'undefined' && cEntry.data.Dates.length > 0) await createMultipleTimeRecords(cEntry);
    else {
      await createSingleTimeRecord(cEntry);
      close();
    }
  };

  const onJobChange = (value, name, display) => {
    const cEntry = { ...entry };
    if (value.length > 0) {
      [cEntry.data[name]] = value;
      cEntry.data.JobDisplay = display;
      cEntry.data.Job = display.substring(0, 6);
    } else {
      cEntry.data[name] = '';
      cEntry.data.JobDisplay = '';
      cEntry.data.Job = '';
    }
    if (mounted.current)
      setEntry(cEntry);
  };

  const onUnionChange = (value, name, display) => {
    const cEntry = { ...entry };
    if (value.length > 0) {
      [cEntry.data[name]] = value;
      cEntry.data[`${name}Display`] = display;
    } else {
      cEntry.data[name] = '';
      cEntry.data[`${name}Display`] = '';
    }
    if (mounted.current)
      setEntry(cEntry);
  };

  // look into this
  const whereClause = () => {
    if (Array.isArray(entry.data.Crew) && entry.data.Crew.length > 0) return [['id', '==', entry.data.Crew[0]]];
    if (!Array.isArray(entry.data.Crew) && entry.data.Crew !== '') return [['id', '==', entry.data.Crew]];
    if (CurrentForeman.value.length > 0) return [['data.Foreman', '==', CurrentForeman.value[0]]];
    return [['id', '>', '0']];
    // return [['data.Foreman', '==', CurrentForeman.value[0]]];
  };

  return (
    <Grid container spacing={1}>
      {user.SuperAdmin && <Grid item xs={12} style={{ padding: '4px 0', fontWeight: 'bold', fontVariant: 'small-caps', textAlign: 'center' }}> time entry id: {entry.id}</Grid>}
      <Grid item xs={12}>
        <div>
          {entry?.data?.id === '' || typeof entry?.data?.id === 'undefined'
            ? 'Entering'
            : 'Modifying'}{' '}
          Time for{' '}
          <span style={{ fontWeight: 'bold' }}>{entry?.data?.JobDisplay}</span>{' '}
          on <span style={{ fontWeight: 'bold' }}>{CurrentDate.display}</span>
          ...
        </div>
      </Grid>
      <Grid item xs={12}>
        <InputWeekdays
          name="Dates"
          label="Multiple Dates"
          value={entry.data.Dates || []}
          onChange={onChange}
          weekStart={1}
          currentDate={entry.data.Date}
          componentWidth="100%"
          hideWeekChanger
        />
      </Grid>
      <Grid item xs={12}>
        <InputDate
          name='Date'
          label='Work Date'
          value={entry.data.Date}
          onChange={onChange}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <InputAutocomplete
          firebaseConfig={firebaseConfig}
          name='Crew'
          label='Employee'
          firestoreOptions={{
            method: 'onSnapshot',
            collection: 'Crew-Manager',
            where: whereClause(),
            orderBy: 'data.EmployeeDisplay',
            valueKey: 'id'
          }}
          value={
            typeof entry.data.Crew === 'string' && entry.data.Crew !== ''
              ? [entry.data.Crew]
              : entry.data.Crew
          }
          onChange={onChange}
          // optionDisplay={["option_EmployeeDisplay", '[ Union: ', 'option_UnionDisplay', ' ]']}
          optionDisplay={(opts) => {
            if (Object.keys(opts).length === 0) return '';

            return `${opts.data.EmployeeDisplay}`;
          }}
          filterDataset={(opts) => {
            if (typeof employee !== 'undefined') {
              const filteredEmps = opts.filter(
                (e) => e.data.Employee[0] === employee
              );
              if (!loadedEmp.flag && filteredEmps.length === 1 && mounted.current) {
                setLoadedEmp({ flag: true, value: [filteredEmps[0].id] });
              }
              if (!loadedEmp && mounted.current) {
                setLoadedEmp({ flag: true, value: undefined });
              }
              return filteredEmps;
            }
            const uniqueOpts = [];

            opts.forEach((opt) => {
              const exists = uniqueOpts.findIndex(
                (u) =>
                  u.data.Union[0] === opt.data.Union[0] &&
                  u.data.Employee[0] === opt.data.Employee[0]
              );
              if (exists === -1) {
                uniqueOpts.push(opt);
              }
            });
            return uniqueOpts;
          }}
          emptyPlaceholder='No crew members available.'
          enterprise={false}
          disablePortal
        />
      </Grid>
      <Grid item xs={12}>
        <InputAutocomplete
          firebaseConfig={firebaseConfig}
          name='Union'
          label='Union'
          firestoreOptions={{
            method: 'onSnapshot',
            collection: 'ENT-Unions',
            valueKey: 'prtunmid'
          }}
          value={
            typeof entry.data.Union === 'string' &&
              entry.data.Union?.trim() !== ''
              ? [entry.data.Union]
              : entry.data.Union
          }
          onChange={onUnionChange}
          optionDisplay={(opts) => {
            if (Object.keys(opts).length === 0) return '';
            // const uniqueOpts = [];

            return `${opts.UnionNumber} - ${opts.UnionDescription}`;
          }}
          enterprise
          disablePortal
        />
      </Grid>
      <Grid item xs={12}>
        <InputAutocomplete
          firebaseConfig={firebaseConfig}
          name='JCTDSCID'
          label='Job Number'
          firestoreOptions={{
            method: 'onSnapshot',
            collection: 'ENT-Jobs',
            where: [['JobStatus', '==', 'Open']],
            valueKey: 'jctdscid'
          }}
          value={
            typeof entry.data.JCTDSCID === 'string' &&
              entry.data.JCTDSCID !== ''
              ? [entry.data.JCTDSCID]
              : entry.data.JCTDSCID
          }
          onChange={onJobChange}
          optionDisplay={(opts) => {
            if (typeof opts.jctdscid === 'undefined') {
              return '';
            }
            return `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription}`;
          }}
          enterprise
          disablePortal
        />
      </Grid>
      <Grid item xs={12}>
        <InputAutocomplete
          firebaseConfig={firebaseConfig}
          name='Picc'
          label='Picc'
          firestoreOptions={{
            method: 'onSnapshot',
            collection: 'ENT-Piccs',
            valueKey: 'jctmstid',
            where: [['jctdscid', '==', entry.data.JCTDSCID]]
          }}
          value={
            typeof entry.data.Picc === 'string' && entry.data.Picc !== ''
              ? [entry.data.Picc]
              : entry.data.Picc
          }
          onChange={onChange}
          optionDisplay={(opts) => {
            if (typeof opts.jctdscid === 'undefined') {
              return '';
            }
            return `${opts.PICC} - ${opts.Description} ${opts.Status === 'Inactive' ? '[Inactive in ECMS]' : ''
              }`;
          }}
          enterprise
          disablePortal
        />
      </Grid>
      <Grid item xs={6}>
        <InputSelect
          name='Shift'
          label='Shift'
          value={entry.data.Shift || ''}
          onChange={onChange}
          options={[
            { label: '1', value: '1', default: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' }
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Regular'
          label='Regular'
          value={entry.data.Regular || ''}
          onChange={onChange}
          min={0}
          max={10}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Overtime'
          label='Overtime'
          value={entry.data.Overtime || ''}
          onChange={onChange}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Doubletime'
          label='Doubletime'
          value={entry.data.Doubletime || ''}
          onChange={onChange}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Holiday'
          label='Holiday'
          value={entry.data.Holiday || ''}
          onChange={onChange}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='Vacation'
          label='Vacation'
          value={entry.data.Vacation || ''}
          onChange={onChange}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6}>
        <InputSelect
          name='SickLeaveType'
          label='Sick Leave Type'
          value={entry.data.SickLeaveType || ''}
          onChange={onChange}
          options={[
            { value: 'SL', label: 'California' },
            { value: 'S1', label: 'MD/DC' },
            { value: 'S2', label: 'IBEW 26 Sick Leave' }
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <InputNumber
          name='SickLeave'
          label='Sick Leave'
          value={entry.data.SickLeave || ''}
          onChange={onChange}
          min={0}
          max={16}
        />
      </Grid>
      <Grid item xs={6} md={3} lg={6} style={{ whiteSpace: 'nowrap' }}>
        <InputSelect
          name='PerDiem'
          label='Per Diem'
          onChange={onChange}
          options={PerDiems}
          value={entry.data.PerDiem || ''}
        />
      </Grid>
      <Grid item xs={6} md={3} lg={6}>
        <InputCheckboxGroup
          name='ExemptFromCertifiedPayroll'
          value={entry.data.ExemptFromCertifiedPayroll || ''}
          onChange={onChange}
        >
          <InputCheckbox value='true' label='Exempt' />
        </InputCheckboxGroup>
      </Grid>
      <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'left' }}>
        <InputCheckboxGroup
          name='NoWorkRecorded'
          value={entry.data.NoWorkRecorded || ''}
          onChange={onChange}
        >
          <InputCheckbox label='No work recorded.' value='true' />
        </InputCheckboxGroup>
      </Grid>
      <Grid item xs={12} lg={6} style={{ textAlign: 'left' }}>
        <InputCheckboxGroup
          name='LessThan8HoursVerified'
          value={entry.data.LessThan8HoursVerified || ''}
          onChange={onChange}
        >
          <InputCheckbox label='Less Than 8 Hours Verified.' value='true' />
        </InputCheckboxGroup>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent='flex-end'>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={() => createTimeRecord()}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' color='secondary' onClick={close}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TimeSummaryModalRecord.propTypes = {
  closePopup: PropTypes.func,
  record: PropTypes.objectOf(PropTypes.any),
  employee: PropTypes.string,
  getRecords: PropTypes.func
};
TimeSummaryModalRecord.defaultProps = {
  closePopup: () => { },
  record: undefined,
  employee: undefined,
  getRecords: () => { }
};

export default TimeSummaryModalRecord;
