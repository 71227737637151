import React, { useState, useContext } from 'react';
import { Modal, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { InputText, InputTextarea } from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { OfflineContext } from '@aldridge/aldg-helpers';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { UserContext } from '../providers/UserProvider';

const SubmitHelpDeskTicket = () => {
  const [record, setRecord] = useState({
    subject: '',
    message: ''
  });
  const user = useContext(UserContext);
  const online = useContext(OfflineContext);
  const [modalOpen, setModalOpen] = useState(false);
  const submitHelpdeskTicket = httpsCallable(functions, 'SubmitIncident');
  const openModal = () => {
    setModalOpen(true);
  };
  const submitTicket = () => {
    if (record.subject !== "" && record.message !== "") {
      submitHelpdeskTicket({ subject: record.subject, message: record.message, sender: user.email }).then(res => {
        if (res.data?.status?.toLowerCase() === 'success') {
          toast.success('Helpdesk Submitted!');
          setModalOpen(false);
        } else {
          toast.error('There was an error submitting the ticket. Please try again later.');
        }
      }).catch(err => {
        toast.error(`${err.message}`);
      });
    } else {
      toast.error("You must fill out the Subject and the Details to submit a ticket.");
    }
  };
  const classes = makeStyles((t) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '2100 !important'
    },
    paper: {
      backgroundColor: t.palette.background.paper,
      border: '2px solid #000',
      boxShadow: t.shadows[5],
      outline: 'none',
      padding: t.spacing(1, 2),
      width: '50%',
      [t.breakpoints.down('xs')]: {
        padding: '6px 8px',
        width: '100%'
      }
    },
    container: {
      display: 'grid',
      gridGap: t.spacing(3),
    },
    button: {
      justifyContent: 'flex-start',
      padding: '2px',
      display: 'flex',
      alignItems: 'center',
      '&$outlined': {
        border: '2px solid #444',
      },
    },
    outlined: {},
    label: { padding: '0px 4px' },
  }))();
  return (
    <>
      <Grid container justifyContent="center">
        <Modal open={modalOpen} className={classes.modal}>
          <div className={classes.paper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '12px',
                flexDirection: 'column'
              }}>
              <InputText name="subject" label="Ticket Subject" value={record.subject} onChange={(e) => setRecord(prev => ({ ...prev, subject: e.target.value }))} />
              <InputTextarea name="message" label="Ticket Details" value={record.message} onChange={(e) => setRecord(prev => ({ ...prev, message: e.target.value }))} />
            </div>

            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              {online ? <Button variant="contained" color="primary" onClick={submitTicket}>Submit</Button> : <div style={{ color: 'red', fontWeight: 'bold' }}>You have no internet connection. Please try submitting a ticket later.</div>}
              &nbsp;
              <Button variant="contained" onClick={() => setModalOpen(false)}>Close</Button>
            </div>
          </div>
        </Modal>
        {online ?
          <Button color='primary' onClick={openModal} variant='contained'>
            Contact Aldridge
          </Button>
          : <div style={{ color: 'red', fontWeight: 'bold' }}>You have no internet connection. Please try submitting a ticket later.</div>
        }
      </Grid>
    </>
  );
};

export default SubmitHelpDeskTicket;