import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal,
  useMediaQuery,
  useTheme,
  Backdrop,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useRecoilState } from 'recoil';
import { InputAutocomplete, InputDate } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import {
  accordionClass,
  accordionSummaryClass,
  accordionDetailsClass
} from '../../theme';
import { _CurrentForeman, _CurrentDate } from '../../_Recoil/atoms';
import { firebaseConfig, firestore } from '../../firebase';

// 42f6704089ac3d20afaba38a1115b415T1JERVI6MjczODYsRVhQSVJZPTE2NTg1MTUxNjAwMDAsS0VZVkVSU0lPTj0x
const ChangeForm = (props) => {
  const { closePopup } = props;
  const [CurrentForeman, setCurrentForeman] = useRecoilState(_CurrentForeman);
  const [CurrentDate, setCurrentDate] = useRecoilState(_CurrentDate);
  const [popupTransaction, setPopupTransaction] = useState({
    Foreman: CurrentForeman.value,
    ForemanDisplay: CurrentForeman.shortDisplay,
    Date: CurrentDate.value
  });
  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedTransaction = { ...popupTransaction };
      changedTransaction[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedTransaction[`${id}Display`] = displayFromTypeahead;
      }
      setPopupTransaction(changedTransaction);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const updateRecords = () => {
    if (popupTransaction.ForemanDisplay !== CurrentForeman.display)
      setCurrentForeman({
        value: popupTransaction.Foreman,
        display: popupTransaction.ForemanDisplay,
        shortDisplay: popupTransaction.ForemanDisplay?.split(' - [')[0]
      });
    if (popupTransaction.Date !== CurrentForeman.value)
      setCurrentDate({
        value: popupTransaction.Date,
        display:
          typeof popupTransaction.Date !== 'undefined' &&
            popupTransaction.Date !== ''
            ? dayjs(popupTransaction.Date).format('ddd MMMM D, YYYY')
            : ''
      });
    closePopup();
  };

  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const useStyles = makeStyles({
    line: {
      padding: '16px'
    },
    foremanLine: {
      padding: '0px 16px'
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        padding: '6px 8px'
      }
    }
  });

  const customClasses = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={customClasses.foremanLine}>
        <InputAutocomplete
          name='Foreman'
          label='Manager'
          value={popupTransaction.Foreman}
          firebaseConfig={firebaseConfig}
          firestoreOptions={{
            valueKey: 'prtmstid',
            collection: 'ENT-Employees',
            where: [['Status', '==', 'A']],
            orderBy: ['LegalLastName', 'LegalFirstName'],
            method: 'onSnapshot'
          }}
          optionDisplay={(opts) => {
            if (Object.keys(opts).length === 0) return '';
            return `${opts.LegalLastName} ${opts.Suffix || ''}, ${opts.PreferredFirstName
              } ${opts.MiddleName} - [${opts.Title} - ${opts.UniversalID
              }]`.replace(/ {2}/g, ' ');
          }}
          firestore={firestore}
          disablePortal
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} className={customClasses.line}>
        <InputDate
          name='Date'
          type='date'
          label='Date'
          value={popupTransaction.Date}
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          textAlign: 'right',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginRight: '14px'
        }}
      >
        <Button
          color='primary'
          variant='contained'
          onClick={updateRecords}
          className={customClasses.button}
        >
          Update
        </Button>
        &nbsp;
        <Button
          variant='contained'
          onClick={closePopup}
          className={customClasses.button}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

const DailyToolbar = () => {
  const [CurrentForeman] = useRecoilState(_CurrentForeman);
  const [CurrentDate] = useRecoilState(_CurrentDate);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };
  const customClasses = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '2100 !important'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      outline: 'none',
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down('xs')]: {
        padding: '6px 8px'
      },
      zIndex: 1
    },
    container: {
      display: 'grid',
      gridGap: theme.spacing(3)
    },
    label: { padding: '0px 4px' },
    editText: {
      textTransform: 'none',
      marginLeft: '5px'
    }
  }))();
  const ac = accordionClass();
  const acsc = accordionSummaryClass();
  const acdc = accordionDetailsClass();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Grid container>
      <Modal
        open={displayBoxOpen}
        onClose={() => displayChangeBox(false)}
        BackdropComponent={Backdrop}
        className={customClasses.modal}
      >
        <div className={customClasses.paper}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: matches ? '6px' : '12px'
            }}
          >
            <h5 style={{ textDecoration: 'underline' }}>
              Update Manager / Date
            </h5>
          </div>

          <div
            style={{
              position: 'relative',
              flex: '1 1 auto',
              padding: matches ? '6px' : '12px'
            }}
          >
            <ChangeForm closePopup={() => displayChangeBox(false)} />
          </div>
        </div>
      </Modal>
      <Grid className={customClasses.container} item xs={12}>
        <Accordion classes={ac} defaultExpanded>
          <AccordionSummary
            classes={{ root: acsc.root, expanded: acsc.expanded }}
            expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
          >
            <Button
              classes={{
                root: acsc.outLinedButton,
                outlined: acsc.outlined,
                label: customClasses.label
              }}
              variant='outlined'
              onClick={(event) => {
                event.stopPropagation();
                displayChangeBox(true);
              }}
            >
              <FontAwesomeIcon
                className={customClasses.editIcon}
                icon={faPen}
              />
              <div className={customClasses.editText}> Update Job Details </div>
            </Button>
          </AccordionSummary>
          <AccordionDetails classes={acdc}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <b>Date: </b>
                  <p className={customClasses.editText}>
                    {CurrentDate.display}
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <b>Manager: </b>
                  <p className={customClasses.editText}>
                    {CurrentForeman.shortDisplay}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

ChangeForm.propTypes = {
  closePopup: PropTypes.func
};
ChangeForm.defaultProps = {
  closePopup: () => { }
};

export default DailyToolbar;
