/* eslint-disable no-await-in-loop */
/* eslint-disable no-unused-vars */
import { collection, getDocs, query } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
//
import { firestore } from '../firebase';
// import { getAnalytics, logEvent } from 'firebase/analytics';
import ErrorBoundary from '../utils/ErrorBoundary';

const Test = () => {
  const [time, setTime] = useState([]);
  // const [tasks, setTasks] = useState([]);
  useEffect(() => {
    console.log('getting records');
    getDocs(query(collection(firestore, 'TimeEntries'))).then((r) => {
      console.log(r);
      let knt = 0;
      console.log('looping records');
      r.forEach((d) => {
        const data = d.data();
        if (data.data.PerDiem === 'false') {
          knt += 1;
          data.data.PerDiem = '';
          // d.ref.set(data, { merge: true });
        }
      });
      console.log('records updated: ', knt);
    });
  }, []);

  // // useEffect(() => {
  // //   console.log('Time', time);
  // //   console.log('Task', tasks);
  // // }, [time, tasks])
  // return <>Test Page</>

  // EXAMPLE OF ANALYTICS BELOW

  // const analytics = getAnalytics();

  // const setForeman = () => {
  //   analytics.setUserProperties({ role: 'foreman' });
  // };
  // const setPayroll = () => {
  //   analytics.setUserProperties({ role: 'payroll' });
  // };
  // const logsmth = () => {
  //   const testProperties = ["test1", "test2", "test3", "test4", "test5"];
  //   const testProperty1 = Math.floor(Math.random() * 11);
  //   const testProperty2 = testProperties[Math.floor(Math.random() * 5)];
  //   // logEvent(analytics, 'test_log', { tp1: testProperty1, tp2: testProperty2 });
  // };

  return (
    <>
      <h1>Testing Analytics</h1>
      <div style={{ padding: '20px' }} />
      <div>
        {/* <button type='button' onClick={setForeman}>Set user to foreman</button>
        <button type='button' onClick={setPayroll}>Set user to payroll</button>
        <button type='button' onClick={logsmth}>Log something</button> */}
      </div>
      {/* <ErrorBoundary componentName='Error Boundary Test Component'>
        <div style={{ width: '100%', height: '20px', padding: '8px' }}>
          <BuggyComponent />
        </div>
      </ErrorBoundary> */}
    </>
  );
};

// class BuggyComponent extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { counter: 0 };
//     this.handleClick = this.handleClick.bind(this);
//   }

//   handleClick() {
//     this.setState(({ counter }) => ({
//       counter: counter + 1
//     }));
//   }

//   render() {
//     const { counter } = this.state;
//     if (counter === 5) {
//       // Simulate a JS error
//       throw new Error('I crashed!');
//     }
//     return <button type='button' onClick={this.handleClick}>Number of Clicks {counter}</button>;
//   }
// }

export default Test;
