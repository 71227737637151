/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSync
} from '@fortawesome/pro-solid-svg-icons';
import { OfflineDataContext } from '../providers/OfflineDataProvider';

const CloudSync = (props) => {
  const { children } = props;
  const offlineData = useContext(OfflineDataContext);
  return (
    <>
      <div style={{ display: 'flex', padding: '5px', justifyContent: 'center', width: '30px', height: '30px', borderRadius: '50%', alignItems: 'center', margin: '0 1px', cursor: 'pointer' }} title="Download Data">
        <FontAwesomeIcon onClick={offlineData.syncData} icon={faSync} />
      </div>
      {children.length > 0 ? <span style={{ cursor: 'pointer' }} onClick={offlineData.syncData} role="button">{children}</span> : null}

    </>
  )
}

CloudSync.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string])
}
CloudSync.defaultProps = {
  children: []
}

export default CloudSync;