import { RecoilRoot } from 'recoil';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loader-spinner';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';

import { OfflineProvider } from '@aldridge/aldg-helpers';
import Header from './_GlobalComponents/Header';
import Footer from './_GlobalComponents/Footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { config } from './firebase';
import ChangelogModal from './ChangelogModal';

const rootElement = document.getElementById('root');

const renderApp = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#3f51b5',
        dark: '#26389C'
      },
      secondary: {
        main: '#f50057',
        dark: '#DC003E'
      },
      info: {
        main: '#e0e0e0'
      }
    }
  });
  const configClass = `${config.projectId.indexOf('-dev') > -1 ? 'bg-dev' : ''
    } ${config.projectId.indexOf('-training') > -1 ? 'bg-training' : ''}`.trim();
  try {
    ReactDOM.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <RecoilRoot>
            <OfflineProvider>
              <UserProvider>
                <OfflineDataProvider>
                  <BrowserRouter>
                    <ToastContainer
                      position='bottom-left'
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                    <Suspense
                      fallback={
                        <div className='loader-container'>
                          <div className='loader-container-inner'>
                            <div className='text-center'>
                              <Loader type='TailSpin' />
                            </div>
                            <h6 className='mt-3'>
                              Please wait while we load the Application
                            </h6>
                          </div>
                        </div>
                      }
                    >
                      <div
                        id='appParent'
                        className={configClass}
                        style={{
                          display: 'flex',
                          height: '100%',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        <ChangelogModal />
                        <Header />
                        <Application />
                        <Footer
                          version='2.5.7'
                          copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`}
                        />
                        <ServiceWorkerWrapper />
                      </div>
                    </Suspense>
                  </BrowserRouter>
                </OfflineDataProvider>
              </UserProvider>
            </OfflineProvider>
          </RecoilRoot>
        </ThemeProvider>
      </StyledEngineProvider>,
      rootElement
    );
  } catch (err) {
    // eslint-disable-next-line
    console.log('RENDERING FAILED');
  }
};
renderApp();
// serviceWorkerRegistration.register();
