import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  Modal,
  Button,
  Tooltip
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAutocomplete } from '@aldridge/aldg-data-components';
import Loader from 'react-loader-spinner';
import { collection, getDocs, onSnapshot, query, doc, setDoc, where, orderBy, getDoc } from 'firebase/firestore';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useRecoilValue, useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { faThumbsUp, faThumbsDown, faTimes, faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { faUser, faUserSlash, faUserXmark } from '@fortawesome/pro-regular-svg-icons';
import { firebaseConfig, firestore } from '../../firebase';
import DailyToolbar from '../dailyUsage/DailyToolbar';

import {
  _CurrentDate,
  _CurrentForeman,
  _MultiJobsSelected,
  _AllDivTime,
  _AllSubJobTime,
  _GroupName
} from '../../_Recoil/atoms';
import { basicStyles, icons, TimeSummaryStyles } from '../../theme';
import TimeSummaryModalRecord from './TimeSummaryModalRecord';
import { weekEndDate } from '../../utils/dateFunctions';
import TimeSummaryTimeTypesFilters from './TimeSummaryTimeTypesFilters';
import TimeSummaryLegend from './TimeSummaryLegend';
import { UserContext } from '../../providers/UserProvider';

const TimeSummaryList = (props) => {
  const { history, checkLockout } = props;
  const CurrentDate = useRecoilValue(_CurrentDate);
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const [NewTimeEntries, setNewTimeEntries] = useState([]);
  const jobsSelected = useRecoilValue(_MultiJobsSelected);
  const [employeeIds, setEmployeeIds] = useState([]);
  const [invalidPiccs, setInvalidPiccs] = useState([]);
  const [employeeRecords, setEmployeeRecords] = useState([]);
  const [filterSelected, setFilterSelected] = useState(0);
  const [aggTimeDisplay, setAggTimeDisplay] = useState([]);
  const [allForDiv, setAllForDiv] = useRecoilState(_AllDivTime);
  const [AllSubJobTime, setAllSubJobTime] = useRecoilState(_AllSubJobTime);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const masterPiccs = [];
  const [badPiccs, setBadPiccs] = useState([]);
  const [selectedRecord] = useState(undefined);
  const [Loading, setLoading] = useState(false);
  const [GroupName, setGroupName] = useRecoilState(_GroupName);
  const user = useContext(UserContext);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool);
  };

  const classes = basicStyles();
  const customClasses = TimeSummaryStyles();

  const iconClasses = icons();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const AddTime = () => {
    if (CurrentForeman.value.length !== 0 && CurrentDate.value !== '') {
      displayChangeBox(true);
    } else {
      toast.error('You must have a Manager and Date Selected to enter time...');
    }
  };
  const getUniqueEmployees = (EmployeePids, uniqueEmployees, crews) => {
    crews.forEach((crew) =>
      EmployeePids.push({
        prtmstid: crew.data().data?.Employee[0],
        name: `${crew.data().data?.EmployeeDisplay}`,
        foreman: [crew.data().data.ForemanDisplay]
      })
    );
    EmployeePids.forEach((emp) => {
      const idx = uniqueEmployees
        .map((e) => e.prtmstid)
        .findIndex((e) => e === emp.prtmstid);
      if (idx === -1) {
        uniqueEmployees.push(emp);
      } else {
        uniqueEmployees[idx].foreman.push(emp.foreman[0]);
      }
    });
    uniqueEmployees.sort((a, b) => {
      const fa = a.name.toLowerCase();
      const fb = b.name.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  };
  const TimeDocs = (time, allDocs) => {
    if (!time.empty) {
      time.forEach((d) => {
        const emp = d.data().data.Employee;
        if (
          allDocs[`Employee${emp}`].time
            .map((r) => r.id)
            .indexOf(d.data().id) === -1
        )
          allDocs[`Employee${emp}`].time.push(d.data());
      });
    }
  };
  const getNewJobsRecords = () => {
    const allCrewMembers = [];
    const uniqueEmployees = [];
    const allTimeCalls = [];
    const allDocs = {};
    setLoading(true);
    let q = query(collection(firestore, 'Crew-Manager'), where('data.Foreman', '==', CurrentForeman.value[0]), orderBy('data.EmployeeDisplay'));
    if (GroupName.length > 0) q = query(q, where("data.GroupName", "array-contains", GroupName[0]));
    allCrewMembers.push(
      getDocs(q)
    );
    Promise.all(allCrewMembers).then((allCrews) => {
      const EmployeePids = [];
      allCrews.forEach((crews) => {
        getUniqueEmployees(EmployeePids, uniqueEmployees, crews);

        let batched = [];
        for (let e = 0; e < uniqueEmployees.length; e++) {
          allDocs[`Employee${uniqueEmployees[e].prtmstid}`] = {
            name: uniqueEmployees[e].name,
            prtmstid: uniqueEmployees[e].prtmstid,
            foreman: uniqueEmployees[e].foreman,
            time: []
          };
          if (batched.length === 10) {
            allTimeCalls.push(
              getDocs(query(collection(firestore, 'TimeEntries'), where('data.Employee', 'in', batched), where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))))
            );
            batched = [];
          }
          batched.push(uniqueEmployees[e].prtmstid);
        }
        allTimeCalls.push(
          getDocs(query(collection(firestore, 'TimeEntries'), where('data.Employee', 'in', batched), where('data.WeekEndDate', '==', weekEndDate(CurrentDate.value))))
        );
        Promise.all(allTimeCalls).then((t) => {
          t.forEach((time) => {
            TimeDocs(time, allDocs);
          });
          setEmployeeIds(uniqueEmployees);
          setNewTimeEntries(allDocs);
          setLoading(false);
        });
      });
    });
  };

  const getCrewRecords = () => {
    getNewJobsRecords();
  };
  const getRecords = (isMounted) => {
    if (CurrentForeman.value.length === 0 || CurrentDate.value === '') {
      if (isMounted) setNewTimeEntries([]);
      return;
    }
    getCrewRecords();
  };
  const getInvalidPiccsByJob = (job) =>
    new Promise((resolve) => {
      const q = query(collection(firestore, 'ENT-Piccs'), where('jctdscid', '==', job), where('Status', '==', 'Inactive'));
      onSnapshot(q, (snap) => {
        const docs = [];
        if (!snap.empty) {
          snap.forEach((d) => docs.push(d.data()));
        }
        resolve(docs);
      });
    });
  const getInvalidPiccs = (mounted) => {
    const invps = [];
    const piccPromises = [];
    jobsSelected.ids.forEach((job) => {
      piccPromises.push(
        getInvalidPiccsByJob(job).then((result) =>
          result.forEach((r) => invps.push(r))
        )
      );
    });
    Promise.all(piccPromises).then(() => {
      if (mounted) {
        setInvalidPiccs(invps);
        setBadPiccs([]);
      }
    });
  };
  const parseNumber = (num) => {
    const defaultNum = 0;
    if (Number.isNaN(parseFloat(num))) return defaultNum;
    return parseFloat(num);
  };

  const checkValidPicc = (atr) => {
    const pArray = atr.Piccs;
    pArray.forEach((p) => {
      if (typeof p === 'undefined') {
        atr.containsInvalidPicc = true;
        const abc = [...badPiccs];
        abc.push(p);
        setBadPiccs((prev) => [...prev, ...abc]);
      } else if (invalidPiccs.map((r) => r.jctmstid).includes(p)) {
        const abc = [...badPiccs];
        abc.push(p);
        atr.containsInvalidPicc = true;
        setBadPiccs((prev) => [...prev, ...abc]);
      }
    });
  };
  const onGroupChange = (event) => {
    setGroupName(event);
  };
  const getNewTimeEntries = (tE, filter, mounted) => {
    const aggTimeRecords = [];
    const r = {
      Employee: '',
      EmployeeDisplay: '',
      Foremans: [],
      Date: '',
      Piccs: [],
      Regular: 0,
      Overtime: 0,
      Doubletime: 0,
      Vacation: 0,
      SickLeave: 0,
      Holiday: 0,
      NoWorkRecorded: '',
      LessThan8HoursVerified: '',
      PerDiem: '',
      containsInvalidPicc: '',
      verified: false
    };

    Object.values(tE).forEach((te) => {
      const { name, time, prtmstid, foreman } = te;
      if (time.length > 0) {
        time.forEach((entry) => {

          const idx = aggTimeRecords.findIndex(
            (atr) => atr.Employee === entry.data.Employee
          );
          const d = dayjs(entry.data.Date).format('ddd');
          if (idx === -1) {
            const cR = JSON.parse(JSON.stringify(r));
            if (entry.data.NoWorkRecorded !== 'true') {
              cR.Piccs.push(entry.data.Picc[0]);
              masterPiccs.push(entry.data.Picc[0]);
            }
            cR.Employee = entry.data.Employee;
            cR.EmployeeDisplay = entry.data.EmployeeDisplay;
            cR.Foremans = foreman;
            cR.Date = entry.data.Date;
            cR[d] = {};
            cR[d].verified = cR[d].verified || [];
            cR[d].verified.push(entry.data.verified || false);
            cR[d].LessThan8HoursVerified =
              entry.data.LessThan8HoursVerified === 'true';
            if (filter === 0 || filter === 7 || filter === 1)
              cR[d].Regular = parseNumber(entry.data.Regular);
            if (filter === 0 || filter === 7 || filter === 2)
              cR[d].Overtime = parseNumber(entry.data.Overtime);
            if (filter === 0 || filter === 7 || filter === 3)
              cR[d].Doubletime = parseNumber(entry.data.Doubletime);
            if (filter === 0 || filter === 7 || filter === 4)
              cR[d].Holiday = parseNumber(entry.data.Holiday);
            if (filter === 0 || filter === 7 || filter === 5)
              cR[d].Vacation = parseNumber(entry.data.Vacation);
            if (filter === 0 || filter === 7 || filter === 6)
              cR[d].SickLeave = parseNumber(entry.data.SickLeave);
            if (filter === 0 || filter === 7)
              cR[d].PerDiem = parseNumber(entry.data.PerDiem);

            if (filter === 0 || filter === 8)
              cR[d].NoWorkRecorded = entry.data.NoWorkRecorded === 'true';
            aggTimeRecords.push(cR);
          } else {
            const cR = aggTimeRecords[idx];
            if (typeof cR[d] === 'undefined')
              cR[d] = {
                Regular: 0,
                Overtime: 0,
                Doubletime: 0,
                Vacation: 0,
                SickLeave: 0,
                Holiday: 0
              };
            if (entry.data.NoWorkRecorded !== 'true') {
              cR.Piccs.push(entry.data.Picc[0]);
              masterPiccs.push(entry.data.Picc[0]);
            }
            cR[d].LessThan8HoursVerified = !cR[d].LessThan8HoursVerified
              ? entry.data.LessThan8HoursVerified === 'true'
              : cR[d].LessThan8HoursVerified;
            if (filter === 0 || filter === 7 || filter === 1)
              cR[d].Regular += parseNumber(entry.data.Regular);
            if (filter === 0 || filter === 7 || filter === 2)
              cR[d].Overtime += parseNumber(entry.data.Overtime);
            if (filter === 0 || filter === 7 || filter === 3)
              cR[d].Doubletime += parseNumber(entry.data.Doubletime);
            if (filter === 0 || filter === 7 || filter === 4)
              cR[d].Holiday += parseNumber(entry.data.Holiday);
            if (filter === 0 || filter === 7 || filter === 5)
              cR[d].Vacation += parseNumber(entry.data.Vacation);
            if (filter === 0 || filter === 7 || filter === 6)
              cR[d].SickLeave += parseNumber(entry.data.SickLeave);
            if (filter === 0 || filter === 7)
              cR[d].PerDiem = parseNumber(entry.data.PerDiem);

            if (filter === 0 || filter === 8)
              cR[d].NoWorkRecorded = !cR[d].NoWorkRecorded
                ? entry.data.NoWorkRecorded === 'true'
                : cR[d].NoWorkRecorded;

            cR[d].verified = cR[d].verified || [];
            cR[d].verified.push(entry.data.verified || false);
          }
        });
      } else {
        const cR = JSON.parse(JSON.stringify(r));
        cR.Employee = prtmstid;
        cR.EmployeeDisplay = name;
        cR.Foremans = foreman;
        aggTimeRecords.push(cR);
      }
    });
    if (filter === 0) {
      aggTimeRecords.forEach((atr) => {
        checkValidPicc(atr);
      });
    }
    if (mounted) {
      setAggTimeDisplay(aggTimeRecords);
    }
  };
  const displayDailyTotalHrs = (day) => {
    if (typeof day === 'undefined') return '';
    if (day.NoWorkRecorded) {
      return 'X';
    }
    return `${parseNumber(day.Regular) +
      parseNumber(day.Overtime) +
      parseNumber(day.Doubletime) +
      parseNumber(day.Holiday) +
      parseNumber(day.Vacation) +
      parseNumber(day.SickLeave)
      }<br>${day.PerDiem !== '' && typeof day.PerDiem !== 'undefined' && day.PerDiem.toString() !== '0'
        ? `$${day.PerDiem.toString()}`
        : ''
      }`;
  };

  const selectFilter = (s) => {
    setFilterSelected(s);
    getNewTimeEntries(NewTimeEntries, s, true);
  };
  const getColor = (day) => {
    if (typeof day !== 'undefined') {
      if (
        day.NoWorkRecorded &&
        (day.Doubletime > 0 ||
          day.Overtime > 0 ||
          day.Vacation > 0 ||
          day.Holiday > 0 ||
          day.SickLeave > 0 ||
          day.Regular > 0)
      )
        return '#dbdd2e';
      if (day.Doubletime > 0 || day.Overtime > 0) return 'red';
      if (day.Regular > 8) return 'darkorange';
      if (day.Vacation > 0 || day.Holiday > 0) return 'blue';
      if (day.SickLeave > 0) return 'green';
      if (day.NoWorkRecorded || day.LessThan8HoursVerified)
        return 'darkturquoise';
    }
    return 'black';
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) getNewTimeEntries(NewTimeEntries, 0, mounted);
    return () => (mounted = false);
  }, [employeeRecords, NewTimeEntries]);

  useEffect(() => {
    let mounted = true;
    const allEmp = [];
    employeeIds.forEach((ei) => {
      allEmp.push(getDoc(doc(firestore, 'ENT-Employees', ei.prtmstid)));
    });
    const allEmpRecords = [];
    Promise.all(allEmp).then((res) => {
      res.forEach((d) => {
        allEmpRecords.push(d.data());
      });
      if (mounted) setEmployeeRecords(allEmpRecords);
    });
    return () => {
      mounted = false;
    };
  }, [employeeIds]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getInvalidPiccs(mounted);
      getRecords(mounted);
    }
    return () => (mounted = false);
  }, [CurrentDate.value, CurrentForeman.value, GroupName]);

  useEffect(() => {
    let mounted = true;
    if (allForDiv) {
      setAllSubJobTime(false);
      getInvalidPiccs(mounted);
      getRecords(mounted);
    }
    return () => (mounted = false);
  }, [allForDiv]);

  useEffect(() => {
    let mounted = true;
    if (AllSubJobTime) {
      setAllForDiv(false);
      getInvalidPiccs(mounted);
      getRecords(mounted);
    }
    return () => (mounted = false);
  }, [AllSubJobTime]);

  const managerOverride = (mA, allApproved) => {
    const r = mA;
    const updatedRecord = { ...r };
    if (allApproved) {
      updatedRecord.data.verified = true;
      return setDoc(doc(firestore, 'TimeEntries', r.id), updatedRecord, { merge: true });
    }
    updatedRecord.data.verified = false;
    return setDoc(doc(firestore, 'TimeEntries', r.id), updatedRecord, { merge: true });
  };
  const layoffOverride = (record, laidOff) => {
    const updatedRecord = { ...record };
    if (laidOff) {
      updatedRecord.data.LaidOff = true;
      return setDoc(doc(firestore, 'TimeEntries', record.id), updatedRecord, { merge: true });
    }
    updatedRecord.data.LaidOff = false;
    return setDoc(doc(firestore, 'TimeEntries', record.id), updatedRecord, { merge: true });
  };

  const verifiedButton = (id) => {
    const verifiedArray = NewTimeEntries[`Employee${id}`].time;
    const verified = verifiedArray.filter((vA) => (vA.data.verified || false) === true).length > 0;
    const unverified = verifiedArray.filter(vA => (vA.data.verified || false) === false).length > 0;
    if (verified && unverified) {
      return (
        <Tooltip title="User has had time added since being approved">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = verifiedArray.map((vT) => managerOverride(vT, false));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}
          >
            <FontAwesomeIcon icon={faQuestion} />
          </Button>
        </Tooltip>
      );
    }
    if (verified && !unverified) {
      return (
        <Tooltip title="Unapprove User Time">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = verifiedArray.map((vT) => managerOverride(vT, false));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}

          >
            <FontAwesomeIcon icon={faThumbsUp} />
          </Button>
        </Tooltip>
      );
    }
    if (!verified && !unverified) {
      // set 
      return (
        <Tooltip title="User has no Time to approve">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              toast.info('No records to approve');
            }}

          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Tooltip>
      );
    }

    if (!verified && unverified) {
      // set  
      return (
        <Tooltip title="Approve User Time">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = verifiedArray.map((vT) => managerOverride(vT, true));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}

          >
            <FontAwesomeIcon icon={faThumbsDown} />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };
  const CrewHours = (crew) => {
    let regHrs = 0;
    let overHrs = 0;
    let otherHrs = 0;

    if (typeof crew.Mon !== 'undefined') {
      regHrs += (crew.Mon.Regular);
      overHrs += (crew.Mon.Overtime);
      otherHrs += (crew.Mon.Doubletime + crew.Mon.Holiday + crew.Mon.Vacation + crew.Mon.SickLeave);
    }
    if (typeof crew.Tue !== 'undefined') {
      regHrs += (crew.Tue.Regular);
      overHrs += (crew.Tue.Overtime);
      otherHrs += (crew.Tue.Doubletime + crew.Tue.Holiday + crew.Tue.Vacation + crew.Tue.SickLeave);
    }
    if (typeof crew.Wed !== 'undefined') {
      regHrs += (crew.Wed.Regular);
      overHrs += (crew.Wed.Overtime);
      otherHrs += (crew.Wed.Doubletime + crew.Wed.Holiday + crew.Wed.Vacation + crew.Wed.SickLeave);
    }
    if (typeof crew.Thu !== 'undefined') {
      regHrs += (crew.Thu.Regular);
      overHrs += (crew.Thu.Overtime);
      otherHrs += (crew.Thu.Doubletime + crew.Thu.Holiday + crew.Thu.Vacation + crew.Thu.SickLeave);
    }
    if (typeof crew.Fri !== 'undefined') {
      regHrs += (crew.Fri.Regular);
      overHrs += (crew.Fri.Overtime);
      otherHrs += (crew.Fri.Doubletime + crew.Fri.Holiday + crew.Fri.Vacation + crew.Fri.SickLeave);
    }
    if (typeof crew.Sat !== 'undefined') {
      regHrs += (crew.Sat.Regular);
      overHrs += (crew.Sat.Overtime);
      otherHrs += (crew.Sat.Doubletime + crew.Sat.Holiday + crew.Sat.Vacation + crew.Sat.SickLeave);
    }
    if (typeof crew.Sun !== 'undefined') {
      regHrs += (crew.Sun.Regular);
      overHrs += (crew.Sun.Overtime);
      otherHrs += (crew.Sun.Doubletime + crew.Sun.Holiday + crew.Sun.Vacation + crew.Sun.SickLeave);
    }
    return [regHrs.toFixed(2), overHrs.toFixed(2), otherHrs.toFixed(2)];
  };

  const layoffButton = (id) => {
    const laidoffArray = NewTimeEntries[`Employee${id}`].time;
    const laidoff = laidoffArray.filter((vA) => (vA.data.LaidOff || false) === true).length > 0;
    const notLaidoff = laidoffArray.filter(vA => (vA.data.LaidOff || false) === false).length > 0;
    if (laidoff && notLaidoff) {
      return (
        <Tooltip title="User has had time added since being laid off">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = laidoffArray.map((vT) => layoffOverride(vT, false));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}
          >
            <FontAwesomeIcon icon={faUserXmark} />
          </Button>
        </Tooltip>
      );
    }
    if (laidoff && !notLaidoff) {
      return (
        <Tooltip title="Un-Layoff User">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = laidoffArray.map((vT) => layoffOverride(vT, false));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}

          >
            <FontAwesomeIcon icon={faUser} />
          </Button>
        </Tooltip>
      );
    }
    if (!laidoff && !notLaidoff) {
      // set 
      return (
        <Tooltip title="No Time For User">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              toast.info('No records to lay off');
            }}

          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </Tooltip>
      );
    }

    if (!laidoff && notLaidoff) {
      // set  
      return (
        <Tooltip title="Layoff User">
          <Button className="icon" disableRipple style={{
            zIndex: '1000',
            backgroundColor: 'transparent',
            maxHeight: '25px',
            minWidth: '25px',
            maxWidth: '25px',
          }}
            onClick={() => {
              const managerApproved = laidoffArray.map((vT) => layoffOverride(vT, true));
              Promise.all(managerApproved).then(getNewJobsRecords());
            }}

          >
            <FontAwesomeIcon icon={faUserSlash} />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };


  return (
    <>
      <DailyToolbar />
      <div style={{ padding: '8px' }} />

      <div style={{ textAlign: 'center' }}>
        Time Summary for Crew Members in
        <b>{`"${CurrentForeman.shortDisplay}" Crew`}</b> for Week Ending{' '}
        <b>{dayjs(weekEndDate(CurrentDate.value)).format('MMMM D, YYYY')}</b>
      </div>
      <Grid container>
        <Modal open={displayBoxOpen} className={classes.modal}>
          <div className={classes.timePaper}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px'
              }}
            >
              <h5 style={{ textDecoration: 'underline' }}>Add Time Record</h5>
            </div>

            <div
              style={{
                position: 'relative',
                flex: '1 1 auto',
                padding: '1rem'
              }}
            >
              <TimeSummaryModalRecord
                record={selectedRecord}
                closePopup={() => displayChangeBox(false)}
                getRecords={getRecords}
              />
            </div>
          </div>
        </Modal>
        <Grid container>
          <Grid item xs={12}>
            {[...new Set(badPiccs)].length > 0 ? (
              <Alert color='warning'>
                {[...new Set(badPiccs)].map((p) => (
                  <div key={p}>{`"${invalidPiccs.filter((r) => r.jctmstid === p)[0]?.PICC
                    } - ${invalidPiccs.filter((r) => r.jctmstid === p)[0]?.Description
                    }" is currently being used and is INACTIVE in JobCost. Please contact your Project Manager or JobCost to reactivate the PICC.`}</div>
                ))}
                <br />
                <div style={{ textAlign: 'left' }}>
                  Employee records with affected PICCs will be highlighted in
                  red.
                </div>
                <div style={{ textAlign: 'left' }}>
                  Please allow up to an hour after the code has been reactivated
                  to see any changes.
                </div>
              </Alert>
            ) : null}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {
              CurrentForeman.value.length > 0 &&
              <InputAutocomplete
                firebaseConfig={firebaseConfig}
                label='Group'
                firestoreOptions={{
                  method: 'get',
                  collection: 'Group-Manager',
                  where: [['data.Status', '==', 'Active'], ['data.Foreman', '==', CurrentForeman.value[0]]],
                  orderBy: ['data.GroupName'],
                  valueKey: 'id',
                }}
                name='GroupName'
                onChange={onGroupChange}
                optionDisplay={(opts) => opts?.data?.GroupName || ""}
                value={GroupName || []}
                enterprise={false}
              />
            }
          </Grid>
        </Grid>
        <Grid container style={{ display: 'contents' }}>
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              height: '56px',
              padding: '8px',
              margin: '8px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              alignSelf: 'flex-start'
            }}
          >
            <Grid
              item
              xs={12}
              className={customClasses.button}
              style={{
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                paddingRight: '0px'
              }}
            >
              <TimeSummaryTimeTypesFilters
                customClasses={customClasses}
                AddTime={AddTime}
                selectFilter={selectFilter}
                filterSelected={filterSelected}
                matches={matches}
                iconClasses={iconClasses}
                checkLockout={checkLockout}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <TableContainer style={{ marginBottom: '16px' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className={classes.tableHeader}>Employee</TableCell>
                <TableCell
                  align='center'
                  style={{ padding: '12px 0px' }}
                  className={classes.tableHeader}
                >
                  Mon
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Tue
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Wed
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Thu
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Fri
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Sat
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Sun
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Total
                </TableCell>
                <TableCell align='center' className={classes.tableHeader}>
                  Verified Hours
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Loading ? (
                <TableRow style={{ textAlign: 'center' }}>
                  <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                    <Loader type='TailSpin' height={56} />
                  </TableCell>
                </TableRow>
              ) : null}
              {Loading
                ? null
                : aggTimeDisplay.map((crew) => (
                  <TableRow
                    className={classes.row1}
                    style={{ cursor: 'pointer' }}
                    key={crew.Employee}
                    title={`${crew.Foremans.filter((c) => c !== '').join(
                      ', '
                    )} are the Foreman(s) who have this person on their Crew.`}
                  >
                    <TableCell
                      className={classes.tableCell}
                      style={{
                        color: crew.containsInvalidPicc ? 'red' : 'black'
                      }}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      {crew.EmployeeDisplay}
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Mon) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Mon)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Tue) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Tue)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Wed) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Wed)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Thu) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Thu)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Fri) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Fri)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Sat) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Sat)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Sun) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: displayDailyTotalHrs(crew.Sun)
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='center'
                      style={{ color: getColor(crew.Total) }}
                      className={classes.tableCell}
                      onClick={() =>
                        history.push(`time-summary/${crew.Employee}`)
                      }
                    >
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <span style={{ width: '50%', textAlign: 'right', fontWeight: 'bold' }}>RG: </span>
                          <span style={{ width: '30%', textAlign: 'right' }}>{CrewHours(crew)[0]}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <span style={{ width: '50%', textAlign: 'right', fontWeight: 'bold' }}>OT: </span>
                          <span style={{ width: '30%', textAlign: 'right' }}>{CrewHours(crew)[1]}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <span style={{ width: '50%', textAlign: 'right', fontWeight: 'bold' }}>OTH: </span>
                          <span style={{ width: '30%', textAlign: 'right' }}>{CrewHours(crew)[2]}</span>
                        </div>

                      </div>
                    </TableCell>
                    <TableCell align='center'
                      className={classes.tableCell}>
                      {verifiedButton(crew.Employee)}
                      {(user.prefab && user.manager) || user.admin ? <br /> : null}
                      {(user.prefab && user.manager) || user.admin ? layoffButton(crew.Employee) : null}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TimeSummaryLegend customClasses={customClasses} />
      </Grid>
    </>
  );
};

TimeSummaryList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  checkLockout: PropTypes.bool
};
TimeSummaryList.defaultProps = {
  history: {
    push: () => { }
  },
  checkLockout: false
};

export default TimeSummaryList;
