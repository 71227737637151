/* eslint-disable import/named */
/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import {
  GroupsList,
  GroupListSelection
} from '../components/groups/GroupListSelection';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import { _HeaderTitle } from '../_Recoil/atoms';
import { basicStyles } from '../theme';
import ErrorBoundary from '../utils/ErrorBoundary';

const Groups = (props) => {
  const { match, history } = props;
  const classes = basicStyles();
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  document.title = 'Group List';
  useEffect(() => setHeaderTitle('Daily Group List'), []);
  return (
    <ErrorBoundary componentName='Groups'>
      <Grid className={classes.gridItem}>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid container>
          <DailyToolbar />
        </Grid>
        {typeof match.params.id === 'undefined' ? (
          <>
            <GroupsList history={history} match={match} />
          </>
        ) : (
          <GroupListSelection history={history} match={match} />
        )}
      </Grid>
    </ErrorBoundary>
  );
};

Groups.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
Groups.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default Groups;
