import * as React from 'react';
import {
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid-premium';
import { createTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap'
    },
  }),
  { defaultTheme, index: 1 }
);

const QuickSearchToolbar = () => {
  const customClasses = useStyles();

  return (
    <div className={customClasses.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
    </div>
  );
};

QuickSearchToolbar.propTypes = {};

export default QuickSearchToolbar;
