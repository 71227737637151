import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const TimeSummaryTimeTypesFilters = (props) => {
  const { customClasses, AddTime, selectFilter, filterSelected, matches, iconClasses, checkLockout } = props;
  return (<>

    <div style={{ textAlign: 'left', marginLeft: '-6px' }}>
      <div style={{ width: '100%', border: 'solid', borderColor: '#3f51b5', borderRadius: '5px', marginTop: '2px' }}>
        <Button disableRipple style={{ borderRadius: '3px 0px 0px 3px' }} className={filterSelected === 0 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(0)}>All</Button>
        <Button disableRipple className={filterSelected === 1 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(1)}>Reg</Button>
        <Button disableRipple style={{ minWidth: matches ? '40px' : '', maxWidth: matches ? '40px' : '' }} className={filterSelected === 2 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(2)}>Over</Button>
        <Button disableRipple className={filterSelected === 3 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(3)}>Dbl</Button>
        <Button disableRipple className={filterSelected === 4 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(4)}>Hol</Button>
        <Button disableRipple className={filterSelected === 5 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(5)}>Vac</Button>
        <Button disableRipple className={filterSelected === 6 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(6)} style={{ minWidth: matches ? '40px' : '', maxWidth: matches ? '40px' : '' }}>Sick</Button>
        <Button disableRipple className={filterSelected === 7 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(7)}>{matches ? 'PD' : 'Per Diem'}</Button>
        <Button disableRipple style={{ minWidth: matches ? '40px' : '', maxWidth: matches ? '40px' : '', borderRadius: '0px 3px 3px 0px' }} className={filterSelected === 8 ? customClasses.filterBtnSelected : customClasses.filterBtn} onClick={() => selectFilter(8)}>NWR</Button>
      </div>
    </div>
    <div style={{ width: '100%', textAlign: '-webkit-right' }}>
      {!checkLockout && <div className={`${iconClasses.container} ${iconClasses.addContainer}`} title="Add Time" style={{ margin: '0px 0px 0px 4px' }}>
        <Button onClick={AddTime} disableRipple className={iconClasses.buttonWrapper}>
          <FontAwesomeIcon icon={faPlus} className={iconClasses.icon} />
        </Button>
      </div>}
    </div>

  </>);

};

TimeSummaryTimeTypesFilters.propTypes = {
  customClasses: PropTypes.objectOf(PropTypes.any).isRequired,
  iconClasses: PropTypes.objectOf(PropTypes.any).isRequired,
  AddTime: PropTypes.func.isRequired,
  selectFilter: PropTypes.func.isRequired,
  filterSelected: PropTypes.number.isRequired,
  matches: PropTypes.bool.isRequired,
  checkLockout: PropTypes.bool.isRequired

};
TimeSummaryTimeTypesFilters.defaultProps = {
};

export default TimeSummaryTimeTypesFilters;