import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { blueGrey, green } from '@mui/material/colors';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      // default: '#3b78e7',
      paper: colors.common.white
    },
    // primary: {
    //   contrastText: '#ffffff',
    //   // main: '#3b78e7'
    // },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  shadows,
  typography
});

const icons = makeStyles(
  {
    container: {
      margin: '0 12px',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      width: '40px',
      height: '40px',
      padding: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      backgroundColor: theme.palette.grey[400],
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[500],
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
      }
    },
    addContainer: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    exportContainer: {
      backgroundColor: blueGrey[400],
      '&:hover': {
        backgroundColor: blueGrey[600]
      }
    },
    saveContainer: {
      backgroundColor: green[400],
      '&:hover': {
        backgroundColor: green[600]
      }
    },
    trashContainer: {
      borderRadius: '30px',

      backgroundColor: '#ffffff',
      '&:hover': {
        backgroundColor: '#ededed'
      }
    },
    redContainer: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    blueContainer: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      }
    },
    greenContainer: {
      backgroundColor: green[400],
      '&:hover': {
        backgroundColor: green[600]
      }
    },
    icon: {
      color: 'white',
      fontSize: '1.5rem'
    },
    trashIcon: {
      color: 'black',
      fontSize: '1.5rem'
    },
    buttonWrapper: {
      backgroundColor: 'transparent',
      padding: '0px',
      minWidth: '40px',
      maxWidth: '40px',
      minHeight: '40px',
      maxHeight: '40px'
    }
  },
  { index: 1 }
);

const basicStyles = makeStyles(
  () => ({
    container: {
      display: 'grid',
      gridGap: theme.spacing(3)
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      fontSize: '2rem',
      transform: 'scale(calc(5/6))'
    },
    simplePaper: {
      padding: theme.spacing(1),
      color: theme.palette.text.error,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1)
    },
    simplePrimaryPaper: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(3)
    },
    modalPaper: {
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      outline: 'none',
      maxHeight: '80%',
      minHeight: '50%',
      overflow: 'auto',
      width: '70%',
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down('sm')]: {
        padding: '6px 8px',
        width: '100%'
      }
    },
    paperWMargin: {
      padding: theme.spacing(2),
      color: theme.palette.text.error,
      whiteSpace: 'nowrap',
      margin: '0px 2px'
    },
    timePaper: {
      zIndex: 1,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      width: '70%',
      maxHeight: '100%',
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      padding: theme.spacing(1, 2, 2)
    },
    tableHeader: {
      fontWeight: 'bold',
      paddingBottom: '0px',
      padding: '0px 0px 0px 16px',
      [theme.breakpoints.down('md')]: {
        padding: '4px'
      }
    },
    tableCell: {
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        padding: '4px'
      }
    },
    gridItem: {
      padding: '5px',
      flexBasis: '0%'
    },
    row: {
      '&:nth-child(even)': {
        backgroundColor: '#efefef'
      },
      '&:nth-child(odd)': {
        backgroundColor: '#fff'
      }
    },
    row1: {
      '&:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '&:nth-child(even)': {
        backgroundColor: '#fff'
      }
    },
    button2: {
      marginRight: theme.spacing(1)
    },
    outlinedButton: {
      button: {
        justifyContent: 'flex-start',
        padding: '2px',
        display: 'flex',
        alignItems: 'center',
        '&$outlined': {
          border: '2px solid #444'
        }
      }
    },
    field: {
      padding: '8px',
      display: 'flex',
      alignItems: 'flex-end'
    }
  }),
  { index: 1 }
);

const accordionClass = makeStyles(
  {
    root: {
      border: '1px solid #ccc',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&$expanded': {
        border: '1px solid #2a56a7',
        margin: '8px 0',
        '& .MuiAccordionSummary-expandIcon': {
          color: 'white'
        }
      }
    },
    expanded: {}
  },
  { index: 1 }
);

const accordionSummaryClass = makeStyles(
  {
    outLinedButton: {
      justifyContent: 'flex-start',
      padding: '2px 6px',
      display: 'flex',
      alignItems: 'center',
      color: 'black',
      '&$outlined': {
        border: '2px solid #444'
      }
    },
    outlined: {},
    root: {
      backgroundColor: '#eee',
      boxShadow: 'inset 0px -19px 4px -19px #aaa',
      color: 'inherit',
      maxHeight: '56px',
      minHeight: '48px',
      '&$expanded': {
        color: 'white',
        maxHeight: '48px',
        minHeight: '48px',
        backgroundColor: 'rgb(59, 120, 231)',
        '& .MuiButtonBase-root': {
          color: 'white'
        },
        '& $outlined': {
          border: '2px solid white'
        }
      },
      editIcon: {
        color: 'black'
      },
      expanded: {}
    },
    expanded: {}
  },
  { index: 1 }
);

const accordionDetailsClass = makeStyles(
  {
    root: {
      flexDirection: 'column'
    }
  },
  { index: 1 }
);

const TimeSummaryStyles = makeStyles(
  () => ({
    button: {
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end'
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '0px'
      }
    },
    filterBtn: {
      textTransform: 'none',
      color: '#3f51b5',
      backgroundColor: 'white',
      borderRadius: '0px',
      whiteSpace: 'nowrap',
      maxHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '30px',
        maxWidth: '30px'
      }
    },
    filterBtnSelected: {
      textTransform: 'none',
      borderRadius: '0px',
      backgroundColor: '#3f51b5',
      color: 'white',
      maxHeight: '40px',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: '#132cb6'
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '30px',
        maxWidth: '30px'
      }
    },
    legendBox: {
      width: '20px',
      height: '20px',
      border: 'solid'
    },
    overEight: {
      color: theme.palette.warning.main
    },
    overTime: {
      color: theme.palette.primary.dark
    },
    doubleTime: {
      color: theme.palette.error.main
    }
  }),
  { index: 1 }
);

const pdfStyles = makeStyles(
  () => ({
    root: {
      margin: '0px',
      width: '720px'
    },
    main: {
      padding: theme.spacing(1),
      fontWeight: 'bold',
      textAlign: 'center',
      boxShadow: '5px 3px 5px -3px rgb(87 87 87 / 20%)',
      color: theme.palette.text.primary,
      fontSize: '16px',
      overflow: 'hidden',
      height: '100%'
    },
    titleBox: {
      padding: theme.spacing(1),
      // margin: '10px',
      fontWeight: 'bold',
      textAlign: 'center',
      boxShadow: '0 3px 5px 2px rgba(87, 87, 87, .2)',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.divider,
      fontSize: '12px',
      overflow: 'hidden',
      height: '100%'
    },
    subTitleBox: {
      padding: theme.spacing(1),
      fontWeight: 'bold',
      border: '1px solid black',
      boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
      textAlign: '-webkit-left',
      backgroundColor: '#d5eaf5',
      fontSize: '12px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: '100%',
      whiteSpace: 'pre-wrap'
    },
    subTitleBoxSecond: {
      padding: theme.spacing(1),
      fontWeight: 'bold',
      border: '1px solid black',
      boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
      textAlign: '-webkit-left',
      backgroundColor: '#d5eaf5',
      fontSize: '12px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'pre-wrap'
    },
    fillable: {
      fontSize: '10px',
      padding: theme.spacing(1),
      fontWeight: 'bold',
      border: '1px solid black',
      boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
      backgroundColor: '#ffffff',
      // margin: '12px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      textAlign: '-webkit-left',
      height: '100%',
      whiteSpace: 'pre-wrap'
    },
    signatureBox: {
      fontSize: '14px',
      padding: theme.spacing(1),
      fontWeight: 'bold',
      border: '1px solid black',
      boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
      backgroundColor: '#d5eaf5',
      // margin: '10px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'pre-wrap'
    },
    signatureBoxSecond: {
      fontSize: '14px',
      padding: theme.spacing(1),
      fontWeight: 'bold',
      border: '1px solid black',
      boxShadow: '0 3px 5px 2px rgba(128, 160, 173, .2)',
      backgroundColor: '#ffffff',
      // margin: '10px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      textAlign: 'center',
      height: '100%',
      whiteSpace: 'pre-wrap'
    }
  }),
  { index: 1 }
);

export {
  theme,
  icons,
  basicStyles,
  accordionClass,
  accordionSummaryClass,
  accordionDetailsClass,
  TimeSummaryStyles,
  pdfStyles
};
