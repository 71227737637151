import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Table, TableContainer, TableCell, TableHead, TableRow, TableBody } from '@mui/material';
import { onSnapshot, collection, query, where, orderBy } from 'firebase/firestore';
import { _CurrentForeman } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';
import { basicStyles } from '../../theme';


const CrewDashboard = () => {
  const [Crew, setCrew] = useState([]);
  const CurrentForeman = useRecoilValue(_CurrentForeman);

  useEffect(() => {
    let mounted = true;
    if (CurrentForeman.value.length > 0) {
      const q = query(
        collection(firestore, 'Crew-Manager'),
        where('data.Foreman', '==', CurrentForeman.value[0]),
        orderBy('data.EmployeeDisplay'));
      onSnapshot(q, (snap) => {
        const all = [];
        snap.forEach(doc => {
          all.push(doc.data());
        });
        if (mounted) {

          setCrew(all);
        }
      });
    } else {
      setCrew([]);
    }
    return () => (mounted = false);

  }, [CurrentForeman]);

  const classes = basicStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: 'white' }}>
            <TableCell className={classes.tableHeader}>Crew Members</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Crew.map((row) => (
            <TableRow key={row.id} className={classes.row1}>
              <TableCell className={classes.tableCell}>
                {row.data.EmployeeDisplay}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


export default CrewDashboard;