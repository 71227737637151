import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from '@mui/material';

const TimeSummaryLegend = (props) => {
  const { customClasses } = props;
  return (
    <Card style={{ backgroundColor: 'white', width: '100%', paddingBottom: '4px' }}>
      <div style={{ textAlign: 'left', width: '100%', padding: '8px 8px 0px 8px' }}>
        <b style={{ marginLeft: '4px' }}>Legend:</b>
        <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
          <div style={{ backgroundColor: 'red' }} className={customClasses.legendBox} />
          <div style={{ margin: '2px 0px 0px 6px' }}>Records with Overtime/Doubletime hours</div>
        </div>
        <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
          <div style={{ backgroundColor: 'darkorange' }} className={customClasses.legendBox} />
          <div style={{ margin: '2px 0px 0px 6px' }}>Records with more than 8 Regular hours</div>
        </div>
        <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
          <div style={{ backgroundColor: 'blue' }} className={customClasses.legendBox} />
          <div style={{ margin: '2px 0px 0px 6px' }}>Records with Holiday/Vacation hours</div>
        </div>
        <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
          <div style={{ backgroundColor: 'green' }} className={customClasses.legendBox} />
          <div style={{ margin: '2px 0px 0px 6px' }}>Records with Sick Leave hours</div>
        </div>
        <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
          <div style={{ backgroundColor: 'darkturquoise' }} className={customClasses.legendBox} />
          <div style={{ margin: '2px 0px 0px 6px' }}>Records with No Work Recorded or Less Than 8 Hours Verified</div>
        </div>
        <div style={{ padding: '4px', width: 'fit-content', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row' }}>
          <div style={{ backgroundColor: '#dbdd2e' }} className={customClasses.legendBox} />
          <div style={{ margin: '2px 0px 0px 6px' }}>Records with No Work Recorded &amp; Hours</div>
        </div>
        <div style={{ padding: '4px 4px 4px 24px', width: 'fit-content', color: 'red', display: 'flex', flexDirection: 'row' }}>
          <div style={{ margin: '2px 0px 0px 6px' }}>Records with Invalid PICCs</div>
        </div>
      </div>
    </Card>
  );
};

TimeSummaryLegend.propTypes = {
  customClasses: PropTypes.objectOf(PropTypes.any).isRequired

};
TimeSummaryLegend.defaultProps = {
};

export default TimeSummaryLegend;