import React, { useContext } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  Typography
} from '@mui/material';
import { OfflineContext } from '@aldridge/aldg-helpers';
import { faClipboard } from '@fortawesome/pro-solid-svg-icons';
import NavItem from './NavItem';
import SidebarData from '../../_GlobalComponents/Menu/SideBarData';
import { UserContext } from '../../providers/UserProvider';
import * as serviceWorker from '../../serviceWorkerRegistration';
import OnlineStatus from '../../_GlobalComponents/OnlineStatus';
import CloudSync from '../../_GlobalComponents/CloudSync';
import IndexedDbWrapper from '../../utils/indexedDbWrapper';
import SubmitHelpDeskTicket from '../../utils/SubmitHelpDeskTicket';
import { config, signOut } from '../../firebase';

const DashboardSidebar = (props) => {
  const user = useContext(UserContext) || {};
  const online = useContext(OfflineContext);
  // eslint-disable-next-line react/prop-types
  const { setApplicationDrawer } = props;
  const fRefreshApplication = () => {
    console.log('begin force refresh...');
    signOut().then(() => {
      console.log('signed out...');
      serviceWorker.unregister().then(async () => {
        console.log('unregistered...');
        localStorage.removeItem('DJR-KEY');
        localStorage.removeItem('OfflineInited');
        window.location.reload();
      });
    });
  };
  const data = JSON.parse(JSON.stringify(SidebarData));
  if (user && !user.payroll && !user.SuperAdmin) {
    data.splice(4, 1);
  }
  const whichDJR = () => {
    const env =
      config.projectId.split('-')[config.projectId.split('-').length - 1];
    switch (env?.toLowerCase().trim()) {
      case 'training':
        return 'https://aldg-djr-training.web.app';
      case 'dev':
        return 'https://aldg-djr-dev.web.app';
      default:
        return 'https://djr.aldridge.app/';
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar src={user.picture} />
        <Typography color='textPrimary' variant='h5'>
          {user.name}
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2, pb: 1, pt: 0 }}>
        <List>
          {data.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={setApplicationDrawer}
            />
          ))}
          {user && (user.manager || user.admin) ? (
            <NavItem
              href='/'
              key='Back to DJR'
              title='Back to DJR'
              icon={faClipboard}
              onClick={() => {
                window.location.href = whichDJR();
              }}
            />
          ) : null}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 1,
          p: 1
        }}
      >
        <div
          style={{ marginBottom: '24px', marginTop: '-12px', border: 'double' }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginTop: '8px'
            }}
          >
            <OnlineStatus />
            Current Online Status: {online ? 'Online' : 'Offline'}
          </div>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <IndexedDbWrapper projectId={config.projectId}>
              View Pending Records
            </IndexedDbWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              marginBottom: '8px'
            }}
          >
            <CloudSync>Download Data</CloudSync>
          </div>
        </div>
        <Typography align='center' gutterBottom variant='h4'>
          Need Help?
        </Typography>
        <div style={{ marginTop: '-8px', paddingBottom: '4px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 1
            }}
          >
            {online ? (
              <Button
                color='primary'
                variant='contained'
                onClick={fRefreshApplication}
              >
                Force Refresh Application
              </Button>
            ) : (
              <div style={{ color: 'red', fontWeight: 'bold' }}>
                You must be online to force refresh your application.
              </div>
            )}
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <Typography align='center' variant='body2'>
            Please contact Customer Support
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <SubmitHelpDeskTicket />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
