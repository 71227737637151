import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import DailyToolbar from '../components/dailyUsage/DailyToolbar';
import { _HeaderTitle } from '../_Recoil/atoms';
import ChangelogList from '../components/changelog/ChangelogList';
import Changelog from '../components/changelog/Changelog';

const ChangelogPage = (props) => {
  document.title = 'Changelog';
  const { match, history } = props;
  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  useEffect(() => setHeaderTitle('Changelog'), []);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ paddingBottom: '8px' }}>
            <h1>{document.title}</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
          <DailyToolbar />
        </Grid>
        <Grid item xs={12}>
          {typeof match.params.id === 'undefined' ? (
            <>
              <ChangelogList history={history} match={match} />
            </>
          ) : (
            <Changelog history={history} match={match} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

ChangelogPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
ChangelogPage.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default ChangelogPage;
