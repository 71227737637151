import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Table, TableContainer, TableCell, TableHead, TableRow, TableBody } from '@mui/material';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { _CurrentForeman, _GroupName } from '../../_Recoil/atoms';
import { firestore } from '../../firebase';
import { basicStyles } from '../../theme';


const GroupDashboard = () => {
  const [Groups, setGroupName] = useState([]);
  const GroupName = useRecoilValue(_GroupName);
  const Foreman = useRecoilValue(_CurrentForeman);

  useEffect(() => {
    let mounted = true;
    if (Foreman.value.length === 0) {
      toast.error('Please select a Manager.');
      return null;
    }
    const q = query(
      collection(firestore, 'Group-Manager'), where('data.Foreman', '==', Foreman.value[0]));
    onSnapshot(q, (snap) => {
      const all = [];
      snap.forEach(doc => {
        all.push(doc.data());
      });
      if (mounted) {
        setGroupName(all);
      }
    });

    return () => (mounted = false);

  }, [GroupName]);

  const classes = basicStyles();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: 'white' }}>
            <TableCell className={classes.tableHeader}>Groups</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Groups.map((row) => (
            <TableRow key={row.id} className={classes.row1}>
              <TableCell className={classes.tableCell}>
                {row.data.GroupName}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupDashboard;