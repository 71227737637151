import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import GlobalStyles from '../components/Styles/GlobalStyles';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import Dashboard from './Dashboard';
import Crew from './Crew';
import Groups from './Groups';
import TimeSummary from './TimeSummary';
import Test from './Test';
import ChangelogPage from './Changelog';
// import { getAnalytics, logEvent } from 'firebase/analytics';

const Application = () => {
  const user = useContext(UserContext);
  const page = () => {
    if (!user) {
      return <SignIn />;
    }

    if (user && (user.admin || user.manager)) {
      return (
        <>
          <GlobalStyles />
          <Switch>
            <Route exact path='/' component={Dashboard} />
            {user.SuperAdmin ? (
              <Route exact path='/test' component={Test} />
            ) : null}
            <Route exact path='/Crew' component={Crew} />
            <Route exact path='/Crew/:id' component={Crew} />
            <Route exact path='/Groups' component={Groups} />
            <Route exact path='/Groups/:id' component={Groups} />
            <Route exact path='/Time-Summary' component={TimeSummary} />
            <Route exact path='/Time-Summary/:id' component={TimeSummary} />
            <Route
              exact
              path='/Changelog'
              component={user.SuperAdmin ? ChangelogPage : NoMatch}
            />
            <Route
              exact
              path='/Changelog/:id'
              component={user.SuperAdmin ? ChangelogPage : NoMatch}
            />

            <Route exact>
              <NoMatch />
            </Route>
          </Switch>
        </>
      );
    }

    return <Unauthorized />;
  };

  return (
    <>
      <div className='appBody'>{page()}</div>
    </>
  );
};
export default Application;
