import React, { useEffect, useState } from 'react';
import { Grid, Card, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { _CurrentDate, _CurrentForeman } from '../../_Recoil/atoms';

import { firestore } from '../../firebase';

const DailyTimeDashboard = () => {
  const CurrentForeman = useRecoilValue(_CurrentForeman);
  const CurrentDate = useRecoilValue(_CurrentDate);
  const [time, setTime] = useState([]);
  const [peopleHours, setPeopleHours] = useState([]);
  if (CurrentForeman.value.length === 0 || CurrentDate.value === '') {
    return 'Need data to get data';
  }
  useEffect(() => {
    let mounted = true;
    const q = query(
      collection(firestore, 'Crew-Manager'),
      where('data.Foreman', '==', CurrentForeman.value[0]),
      orderBy('data.EmployeeDisplay')
    );
    getDocs(q).then((querySnapshot) => {
      const crewMembers = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          crewMembers.push(doc.data());
        });
      }
      const p = [];
      for (let i = 0; i < crewMembers.length; i++) {
        p.push(
          getDocs(query(collection(firestore, 'TimeEntries'),
            where('data.Employee', '==', crewMembers[i].data.Employee[0]),
            where('data.Date', '==', CurrentDate.value),
            orderBy('data.EmployeeDisplay')))
        );
      }
      Promise.all(p).then((r) => {
        const allR = [];
        r.forEach((docs) => {
          docs.forEach((doc) => {
            allR.push(doc.data());
          });
        });

        if (mounted) setTime(allR);
      });
    });

    return () => (mounted = false);
  }, [CurrentForeman.value, CurrentDate.value]);

  const aggHours = (record) => {
    const { Regular, SickLeave, Holiday, Vacation, Overtime, Doubletime } =
      record.data;
    let total = 0;
    if (!Number.isNaN(parseFloat(Regular))) total += parseFloat(Regular);
    if (!Number.isNaN(parseFloat(SickLeave))) total += parseFloat(SickLeave);
    if (!Number.isNaN(parseFloat(Holiday))) total += parseFloat(Holiday);
    if (!Number.isNaN(parseFloat(Vacation))) total += parseFloat(Vacation);
    if (!Number.isNaN(parseFloat(Overtime))) total += parseFloat(Overtime);
    if (!Number.isNaN(parseFloat(Doubletime))) total += parseFloat(Doubletime);
    return total.toFixed(2);
  };

  useEffect(() => {
    let mounted = true;
    const people = [];
    time.forEach((t) => {
      const existingRecord = people.findIndex(
        (p) => p.prtmstid === t.data.Employee
      );
      if (existingRecord > -1) {
        people[existingRecord].totalHours = (
          parseFloat(people[existingRecord].totalHours) +
          parseFloat(aggHours(t))
        ).toFixed(2);
      } else {
        people.push({
          prtmstid: t.data.Employee,
          Employee: t.data.EmployeeDisplay?.split(' - [')[0],
          totalHours: aggHours(t)
        });
      }
    });
    if (mounted) setPeopleHours(people);
    return () => (mounted = false);
  }, [time]);

  return (
    <Card raised>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ fontWeight: 'bold', fontSize: '1.25rem', padding: '10px 0' }}
        >
          Daily Time for {CurrentDate.display}
        </Grid>
        {peopleHours.length !== 0 ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={6}
                style={{ fontWeight: 'bold', paddingBottom: '5px' }}
              >
                Employee
              </Grid>
              <Grid
                item
                xs={6}
                style={{ fontWeight: 'bold', paddingBottom: '5px' }}
              >
                Total Hours
              </Grid>
              {peopleHours.map((r, idx) => (
                <Grid
                  item
                  xs={12}
                  key={r.prtmstid}
                  style={{
                    backgroundColor: idx % 2 === 0 ? '#efefef' : '#fff',
                    padding: '5px 0'
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      style={{ textAlign: 'left', paddingLeft: '5px' }}
                    >
                      {r.Employee}
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                      {r.totalHours}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid item style={{ width: '100%', textAlign: '-webkit-center' }}>
            <Card
              style={{
                padding: '16px',
                margin: '0px 16px 13.25px 16px',
                width: 'fit-content'
              }}
            >
              <i>No time has been entered for this day.</i>
              <div style={{ margin: '8px 8px 0px 8px' }}>
                <Link to='/time-entry'>
                  <Button variant='contained' color='primary'>
                    Add time for {CurrentDate.display}
                  </Button>
                </Link>
              </div>
            </Card>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default DailyTimeDashboard;
