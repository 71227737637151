import React, { useContext } from 'react';
import {
  Button
} from '@mui/material';
import { UserContext } from '../providers/UserProvider';
import { auth } from '../firebase';

const Unauthorized = () => {
  const user = useContext(UserContext);
  return (
    <div data-testid="unauthorized">
      <div>
        You are currently unauthorized for this application with this account ({user.email}).
        Please contact the helpdesk if you should have access.
      </div>
      <div>
        <Button type="button" variant="contained" onClick={() => { auth.signOut(); }}>Sign out</Button>
      </div>
    </div>
  );
};
export default Unauthorized;
